.terms-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  height: 750px;
  &::-webkit-scrollbar {
   display: none;
   
  }
  h6 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    color: #000000;
    padding: 12px;
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
  }

  .terms-content {
    padding: 30px 0 0 0;
    p {
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #4a4a4a;
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-terms-section {
    .terms-section .terms-content p {
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-terms-section {
    .terms-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px;
      height: 100%;
      margin-top: 20px;
    }
    .terms-section .terms-content p {
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-terms-section {
    .terms-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px;
      height: 100%;
      margin-top: 20px;
    }
    .terms-section .terms-content p {
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #4a4a4a;
    }
    .settings .setting-tab .inputfield a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #000000;
      padding-left: 25px;
    }
  }
}

@media (min-width: 1199px) and (max-width: 1500px) {
  .main-terms-section{
    .terms-col {
      width: 45%;
    }
  
    .setting-col {
      width: 30% !important;
    }
  }

}
@media (min-width: 1025px) and (max-width: 1198px) {
  .main-terms-section {
    .terms-col {
      width: 40%;
    }

    .setting-col {
      width: 30% !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-terms-section {
    .terms-col {
      width: 45%;
    }

    .setting-col {
      width: 44% !important;
    }
  }
}
