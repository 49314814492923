.pl-0{
  padding-left: 0 !important;
}

thead{
  tr
  {

  }

}

.main-cart-table-section {
  
  .cart-table {
    background: #ffffff !important;
    border-radius: 20px;
    padding: 20px 20px 20px 20px;
    height: 557px;
    table {
      thead {
        background: #0076be;
        border-radius: 20px;

        tr {
          th {
            border-style: none;
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
            padding-top: 17px;
            padding-bottom: 17px;
          }
          th:first-child{
            border-top-left-radius: 10px !important;
            border-bottom-left-radius: 10px !important;
          }
          th:last-child{
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
          }
          th:not(:first-child){
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            vertical-align: middle;
            text-align: center;
          }

        }
      }
    }
    .order-img-title {
      display: flex;
      align-items: center;
      h2 {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
        padding-left: 55px;
        margin: 0;
      }
    }
    .quantity {
      p {
        margin: 0;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #979797;
      }
    }
    .price {
      p {
        margin: 0;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }
    }
    .total {
      p {
        margin: 0;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #000000;
      }
    }
    .edit-delete-btns {
      a:first-child {
        margin-right: 23px;
      }
    }
  }

  .amount-details {
    background: #ffffff !important;
    border-radius: 20px;
    padding: 20px 20px 20px 20px;
    h1 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      padding-bottom: 24px;
    }
    .checkout-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      margin-top: 29px;
      p {
        margin: 0;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        background: #f5f8ff;
        border-radius: 10px;
        /* padding: 19px 8px; */
        /* width: 45%; */
        width: 248px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        margin-right: 40px;
        span {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
        }
      }
      a {

        background: #0076be;
        border-radius: 10px;
      font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        /* padding: 19px 8px; */
        /* width: 45%; */
        width: 248px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        span{
          margin-right: 10px;
        }
      }
    }
  }
}




@media screen and (max-width:1300px)
{

.cart-table > .table {
    min-width: 700px;
}
.cart-table {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    will-change: unset;
}
::-webkit-scrollbar {
  height: 4px;              /* height of horizontal scrollbar ← You're missing this */
  width: 4px;               /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.main-cart-table-section .amount-details .checkout-total p {
  font-size: 18px;

}
.main-cart-table-section .amount-details .checkout-total a {
  font-size: 14px;
}
}

@media screen and (max-width:991px)
{
  .main-cart-table-section .amount-details .checkout-total {
display: block;
}
  .main-cart-table-section .amount-details .checkout-total p {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}
.main-cart-table-section .amount-details .checkout-total a {

  width: 100%;
  text-align: center;
  display: block;
}
.cart-table-div.col-xxl-8.col-md-7 {
  width: 100%;
  margin-bottom: 20px;
}

}
