.view-button{

        border-radius: 10px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        background-color: #0076BE;
        border-radius: 10px;
        /* padding: 15px 30px; */
        transition: 0.2s ease-in;
        text-decoration: none;
        cursor: pointer;
        width: 126px;
        height: 45px;
        line-height: 45px;
        text-align: center;
    &:focus{
        outline: none;
        box-shadow: none;
    }

    &:hover{
        background: #FFFFFF;
        color: #0076BE;
    }
}

.slider-btn{
    background: #FFFFFF;
    border: 1px solid #ADB3BC;
    border-radius: 10px;
    padding: 14px 18px;
    cursor: pointer;
    
    &:focus{
        outline: none;
        box-shadow: none;
    }
    svg{
        transform: translateY(-2px);
    }
}

.product-nav-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2{
      font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        color: #000000;
    }
    
    .slider-btns{
        display: flex;
        align-items: center;
        .view-button{
            margin-right: 30px;
        }
        .slider-arrow-btns{
           
            .slider-btn:not(:first-child){
                margin-left: 10px;
            }
        }
    }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
    .view-button{
        font-size: 16px;    
    }
    
    .slider-btn{
        // padding: 14px 18px;
    }
    .product-nav-heading{
        display: block;
        justify-content: space-between;
        align-items: center;
    
        h2{
            font-size: 24px;
        }
        
        .slider-btns{
            display: flex;
            align-items: center;
            justify-content: space-between;
        
            .view-button{
                margin-right: 30px;
            }
            .slider-arrow-btns{
               
                .slider-btn:not(:first-child){
                    margin-left: 10px;
                }
            }
        }
    }
    
}
 