.confirmed {
  color: #48bf91;
}

.pending {
  color: #ff8847;
}

.right-border {
  border-right: 1px solid rgb(173, 179, 188, 0.5) !important;
  border-radius: 0 !important;
}

.main-orders-section {
  .order-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 22px 24px 22px 24px;
    height: 100%;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      margin-bottom: 0;
      padding-top: 10px;
    }
    .border-bottomm {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      margin: 15px 0 0 0;

    }

    .tabs-section {
      ul {
        // justify-content: center;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        // width: 432px;
        // margin: 0 auto;
        .nav-link {
          background-color: transparent;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #adb3bc;
          margin: 16px 0;
          &.active {
            color: #0076be;
          }
        }
      }

      .tab-content {
        .present-orders {
          .order-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            border-bottom: 1px solid rgb(173, 179, 188, 0.5);
            padding-bottom: 20px;

            .img-title-div {
              display: flex;
              align-items: center;
              .title-date {
                padding-left: 20px;
                p {
                font-family: $secondary-fonts;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #979797;
                  margin: 0;
                }
                h2 {
                font-family: $secondary-fonts;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  color: #000000;
                  margin-top: 4px;
                  margin-bottom: 0;
                }
              }
            }
            .order-quantity {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #979797;
                margin: 0;
              }
            }
            .order-price {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin: 0;
              }
            }
            .status {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                margin: 0;
              }
              a {
                display: block;
                text-align: right;
                margin-top: 9px;
              }
            }
          }
        }

        .past-orders {
          .order-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            border-bottom: 1px solid rgb(173, 179, 188, 0.5);
            padding-bottom: 20px;

            .img-title-div {
              display: flex;
              align-items: center;
              .title-date {
                padding-left: 20px;
                p {
                font-family: $secondary-fonts;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  color: #979797;
                  margin: 0;
                }
                h2 {
                font-family: $secondary-fonts;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 18px;
                  color: #000000;
                }
              }
            }
            .order-quantity {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #979797;
                margin: 0;
              }
            }
            .order-price {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #000000;
                margin: 0;
              }
            }
            .status {
              p {
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                margin: 0;
              }
              a {
                display: block;
                text-align: right;
                margin-top: 9px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .myorder-details-col {
    width: 83.33%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .myorder-details-col.col-xxl-6.col-xl-8.col-md-8.col-sm-12 {
    width: 100%;
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) {
  .myorder-tab-content {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    will-change: unset;
  }

  .myorder-pills-home {
    min-width: 700px;
  }

  div#pills-profile {
    min-width: 500px;
  }
  ::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
}
