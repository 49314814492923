// .cross-icon{
//   position: absolute;
//   top: 45px;
//   right: 15px;

// }
.profilepanel {
  position: fixed;
  top: 70px;
  right: 100px;
  display: none;
  z-index: 9;
  &.active {
    display: block;
  }
  &.noactive {
    display: none;
  }
}
.myprofile-popup {
  width: 399px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 15px 45px 15px;

  &::-webkit-scrollbar {
    width: 0;
  }
  .myprofile-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 10px;

    h1 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;

      span {
        font-family: $primary-fonts;
        color: #48bf91;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .bottom-border {
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    margin-top: 20px;
    margin-bottom: 0;
  }
  .profilesection {
    display: flex;
    align-items: center;
    margin: 27px 0 0 0;

    .infodiv {
      padding-left: 31px;

      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }
      p {
        margin: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
      }
    }

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
  .bio {
    font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
    margin-top: 12px;
  }
  .myprofile-nav {
    height: calc(100vh - 425px);

    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .myprofile-side-menu {
      background: #ffffff;
      border-radius: 20px;
      position: relative;
      .inputfield {
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        display: flex;
        align-items: center;
        position: relative;
        padding: 20px 0;
        a {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin: 0;
          padding-right: 2px;

          &.active {
            color: #0076be;
            &::after {
              display: flex;
              content: " ";
              background-image: url("/src/assets/images/icons/arrow-icon.svg");
              background-repeat: no-repeat;
              height: 28px;
              width: 28px;
              position: absolute;
              right: -10px;
              bottom: 8px;
              align-items: center;
              margin: 0;
              padding: 0;
            }
          }
        }
        span {
          background: #48bf91;
          border-radius: 50%;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          color: #ffffff;
          padding: 2px 5px;
          margin-left: auto;
        }
        svg {
          margin-right: 25px;
          margin-left: 25px;
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .profilepanel {
    position: fixed;
    top: 70px;
    right: 0;
    left: 0;
    display: none;
    z-index: 9;
    width: 100%;
    &.active {
      display: block;
    }
    &.noactive {
      display: none;
    }
  }
  .myprofile-popup {
    width: 100%;
    // height: 650px;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;
    overflow-y: scroll;

    .myprofile-heading-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 21px;
      margin-top: 0;
      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        span {
          font-family: $primary-fonts;
          color: #48bf91;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .bottom-border {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      margin-top: 20px;
    }
    .profilesection {
      display: flex;
      align-items: center;

      .infodiv {
        padding-left: 31px;

        h1 {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
        p {
          margin: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
      }

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .bio {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #4a4a4a;
      margin-top: 12px;
    }
    .myprofile-nav {
      // height: calc(100vh - 425px);
      // overflow: auto;
      .myprofile-side-menu {
        background: #ffffff;
        border-radius: 20px;
        position: relative;
        .inputfield {
          border-bottom: 1px solid rgb(173, 179, 188, 0.5);
          display: flex;
          align-items: center;
          position: relative;
          padding: 20px 0;
          a {
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            margin: 0;
            padding-right: 2px;

            &.active {
              color: #0076be;
              &::after {
                display: flex;
                content: " ";
                background-image: url("/src/assets/images/icons/arrow-icon.svg");
                background-repeat: no-repeat;
                height: 28px;
                width: 28px;
                position: absolute;
                right: -10px;
                bottom: 8px;
                align-items: center;
                margin: 0;
                padding: 0;
              }
            }
          }
          span {
            background: #48bf91;
            border-radius: 50%;
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #ffffff;
            padding: 2px 5px;
            margin-left: auto;
          }
          svg {
            margin-right: 25px;
          }
        }
      }
    }
  }
}
