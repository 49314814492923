.multi-range-slider {
  display: flex;
  position: relative;
  border: none !important;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: none !important;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  .bar-inner {
    background-color: #0076be !important;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    position: relative;
    border: none !important;
    justify-content: space-between;
    box-shadow: none !important;
  }
  .thumb::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #0076be !important;
    background: #0076be !important;
    border-radius: 50%;
    box-shadow: none !important;
    z-index: 1;
    margin: -8px -12px;
    cursor: pointer;
  }
  .bar-left {
    padding: 1px 0px !important;
    background-color: #adb3bc !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .bar-right {
    padding: 1px 0px !important;
    background-color: #adb3bc !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .caption {
    display: flex !important;
    span {
      margin-top: 130px;
    }
    .max-caption {
      background-color: transparent !important;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000 !important;
      border-radius: none !important;
      box-shadow: none !important;
      &::before {
        content: "$";
      }
    }
    .min-caption {
      background-color: transparent !important;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #000000 !important;
      border-radius: none !important;
      box-shadow: none !important;
      &::before {
        content: "$";
      }
    }
  }

  // .multi-range-slider .thumb:active .caption
}
// .range-slider-container{
//   &:has(attr(data-currency)){
//     .min-caption{
//       background: red;
//       &::before{
//         content: attr(data-currency) !important;
//       }
//     }
//   }
//   // [data-currency]:before {
//   //   content: attr(data-content);
//   // }
// }

.main-filter {
  .filter-container {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 40px;
    border-radius: 20px;
    max-width: 586px;
    width: 586px;
    // max-height: 499.83px;

    .heading-div {
      position: relative;
      margin-bottom: 50px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #000000;
        padding-top: 29px;
        padding-bottom: 24px;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      }

      .cross-icon {
        position: absolute;
        right: 20px;
        top: 27px;
      }
    }
    .main-filter-div {
      padding-left: 70px;
      padding-right: 70px;
      .form-floating {
        margin-bottom: 30px;
      }
      .form-select {
        display: block;
        width: 100%;
        background-color: #fff;
        background-image: url("../images/icons/drop-down-icon.svg");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 10px 12px;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        padding-top: 25px;
        padding-left: 3px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        &:focus {
          box-shadow: none;
        }
      }
      .form-floating > label {
        padding: 1rem 0;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #4a4a4a;
      }

      .filter-div {
        padding-top: 11px;
        label {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #4a4a4a;
        }
      }
      button {
        background: #0076be;
        border-radius: 10px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        width: 100%;
        border: none;
        padding: 14px 0;
        margin-top: 40px;
        margin-bottom: 50px;
        transition: 0.2s ease-in;
        &:hover {
          border: 1px solid #0076be;
          background-color: #ffffff;
          color: #0076be;
        }
      }
    }
    .multi-range-slider{
      padding: 20px 0;
    }
  }
}

@media (max-width: 786px) {
  .main-filter {
    .filter-container {
      background: #ffffff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 40px;
      border-radius: 20px;
      max-width: 100%;
      max-height: 499.83px;
      .main-filter-div {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}
