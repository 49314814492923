// .side-panel{
//   position:fixed;
//   top: 50px;
//   right: 100px;
//   .modal-dialog{
//     max-width: 440px;
//     margin: 0px 0px 0px auto;
//   }
// }
// .cross-icon{
//   position: absolute;
//   top: 45px;
//   right: 15px;

// }
.notificationpanel {
  position: fixed;
  top: 70px;
  right: 100px;
  display: none;
  z-index: 9;
  &.active {
    display: block;
  }
  &.noactive {
    display: none;
  }
}

.unread-bg {
  background: #f5f8ff;
  border-radius: 10px;
}

.top-border {
  border-top: 1px solid rgb(173, 179, 188, 0.5);
  background: #ffffff;
  border-radius: 0;
}

.notification-popup {
  width: 440px;
  height: 100%;

  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 15px 45px 15px;

  .notification-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 21px;

    h1 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;

      span {
        font-family: $primary-fonts;
        color: #48bf91;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .bottom-border {
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
  }
  .main-notification {
    height: 590px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    
    .notification-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 14px;
      margin-bottom: 10px;

      .store-heading {
        margin-right: 20px;
      }
      h2 {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        margin: 0;
      }
      p {
        /* margin-bottom: auto; */
        font-family: "SF Pro Text", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #818181;
        /* margin-top: 5px; */
        margin-bottom: auto;
      }
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .notificationpanel {
    position: fixed;
    top: 70px;
    right: 100px;
    display: none;
    z-index: 9;
    &.active {
      display: block;
    }
    &.noactive {
      display: none;
    }
  }

  .unread-bg {
    background: #f5f8ff;
    border-radius: 10px;
  }

  .top-border {
    border-top: 1px solid rgb(173, 179, 188, 0.5);
    background: #ffffff;
    border-radius: 0;
  }

  .notification-popup {
    width: 440px;
    height: 100%;

    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;

    .notification-heading-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 21px;
      margin-top: 21px;

      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        span {
          font-family: $primary-fonts;
          color: #48bf91;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .bottom-border {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    }
    .main-notification {
      
      .notification-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 14px;
        margin-bottom: 10px;

        .store-heading {
          margin-right: 20px;
        }
        h2 {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          margin: 0;
        }
        p {
          margin-bottom: auto;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #818181;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .notificationpanel {
    position: fixed;
    top: 70px;
    right: 0;
    left: 0;
    display: none;
    z-index: 9;
    width: 100%;
    &.active {
      display: block;
    }
    &.noactive {
      display: none;
    }
  }

  .unread-bg {
    background: #f5f8ff;
    border-radius: 10px;
  }

  .top-border {
    border-top: 1px solid rgb(173, 179, 188, 0.5);
    background: #ffffff;
    border-radius: 0;
  }

  .notification-popup {
    width: 100%;
    height: 100%;

    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;

    .notification-heading-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 21px;
      margin-top: 21px;

      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;

        span {
          font-family: $primary-fonts;
          color: #48bf91;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .bottom-border {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    }
    .main-notification {
      height: 420px;
      .notification-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 14px;
        margin-bottom: 10px;

        .store-heading {
          margin-right: 20px;
          padding-left: 10px;
        }
        h2 {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #000000;
          margin: 0;
        }
        p {
          margin-bottom: auto;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #818181;
        }
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }
  }
}
