.main-wallet-section{

    .wallet-section{
        padding: 20px 24px;
        background: #FFFFFF;
        border-radius: 20px;
        h6{
          font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            padding: 12px 0;
        }
        .available-credits{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 0;

            p{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #000000;
            }
        }
    }

    .mycard-section{
        padding: 20px 24px;
        background: #FFFFFF;
        border-radius: 20px;
        margin: 30px 0;
        h6{
          font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            padding: 12px 0;
        }
        
        .wallet-card{
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            padding: 14px 0;
            h2{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #000000;
            }
            p{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #4a4a4a;
            }
        }
        .add-new-btn{
            padding: 20px 0;
            a{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                color: #0076BE;
                cursor: pointer;
                span{
                  font-family: $secondary-fonts;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #0076BE;
                    cursor: pointer;
                    padding-left: 5px;
                    padding-bottom: 5;
                }
            }
        }
    }

    .transaction-history{
        padding: 20px 24px;
        background: #FFFFFF;
        border-radius: 20px;
        height: 100%;
        h6{
          font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            padding: 12px 0;
        }

        .historycard{
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            padding: 10px 0;
            h2{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;

                color: #000000;

            }
            .date{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #4A4A4A;
                margin: 5px 0;


            }
            .price{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #000000;
                
            }
        }
    }
}

@media (min-width:992px) and (max-width:1024px)
{
.credit-card-history-main-div.col-xxl-9.col-xl-9.col-md-9 {
    width: 90%;
}

}
@media (min-width:768px) and (max-width:991px)
{
.wallet-credit-card-col.col-xxl-5.col-xl-5.col-lg-6.col-sm-6 {
    width: 50%;
}
.wallet-credit-card-history.col-xxl-5.col-xl-5.col-lg-6.col-md-9.col-sm-6 {
    width: 50%;
}
.credit-card-history-main-div.col-xxl-9.col-xl-9.col-md-9 {
    width: 90%;
}
.person-purchase {
    width: 100%;
    text-align: left !important;
}
.person-name.col-xl-6.col-lg-8.col-md-8 {
    width: 100%;
}
.main-wallet-section .mycard-section .wallet-card .col-md-6 {
    width: 100%;
    margin-top: 20px;
}
.my-auto.text-end.cards-svgs.col-md-3 {
    text-align: left !important;
}
}

// .main-wallet-section .mycard-section {
//  min-height: 664px;
//  margin-bottom: 0 !important;
// }

@media screen and (max-width:767px)
{
.my-auto.text-end.cards-svgs.col-md-3 {
    text-align: left !important;
}
.main-wallet-section .mycard-section .wallet-card h2 {
    margin-top: 20px;
}
.person-purchase {
    width: 100%;
    text-align: left !important;
}

}

@media screen and (max-width:575px)
{
    .wallet-credit-card-col.col-xxl-5.col-xl-5.col-lg-6.col-sm-6 {
        margin-bottom: 20px;
    }
}