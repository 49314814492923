.logout-popup {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 586px;
  height: 350px;
  margin: auto auto;

  .bottom-border {
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
  }

  h1 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000000;
    padding: 25px 0;
  }

  p {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #000000;
    margin-top: 29px;
  }
  .yes-btn {
    font-family: $primary-fonts;
    background: #0076be;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 15px 94px;
  }
  .no-btn {
    font-family: $primary-fonts;
    background: #ffffff;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #adb3bc;
    padding: 15px 94px;
  }
  .btn-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 80px;
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .logout-popup {
    width: 100%;
    height: 350px;
    .yes-btn {
      font-size: 18px;
      padding: 10px 50px;
    }
    .no-btn {
      font-size: 18px;
      padding: 10px 50px;
    }
  }
}
