.banner-section {

  .banner-wrapper {
    background-image: url("../images/banner-img.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: #a19cde;
    padding: 30px 40px;
    border-radius: 20px;
    height: 500px;

    .banner-content {
      h1 {
      font-family: $primary-fonts;;
        font-style: normal;
        font-weight: 400;
        font-size: 54px;
        line-height: 93px;
        color: #FFFFFF;
      }
      p {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        color:#FFFFFF;
        margin-top: 29px;

      }
    }
  }
}




@media screen and (max-width: 1199px){
    .banner-section {
        .banner-wrapper {
          background-position: 800px;
        }
      }
      
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
    .banner-section {

        .banner-wrapper {

          padding: 30px 40px;
          border-radius: 20px;
          height: 500px;
      
          .banner-content {
            h1 {
            font-family: $primary-fonts;;
              font-style: normal;
              font-weight: 400;
              font-size: 32px;
              line-height: 50px;
              color: #FFFFFF;
            }
            p {
            font-family: $secondary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 29px;
              color:#FFFFFF;
            }
          }
        }
      }
}
 