//footer common styling
.footer-heading {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
}
.style-list {
  list-style: none;
  padding: 0;
font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
}
.anchor-style {
  text-decoration: none;
  color: #ffffff;
}
.anchor-style:hover {
  color: #ed1c24;
}

//footer main styling
.footer {
  background-color: #000000;
  .footer-1 {
    padding: 100px 0 30px 0;
    p {
      padding-top: 30px;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #ffffff;
      width: 81%;
    }
  }
  .footer-2 {
    padding: 100px 0 30px 0;
    h2 {
    }
    .footerlists {
      display: flex;
      .first-list {
      }
      .second-list {
        padding-left: 70px;
      }
    }
  }
  .footer-3 {
    padding: 100px 0 30px 0;
    img {
      padding: 10px 0;
    }

    .app-section{
        a{
            display: block;
        }
    }
  }
  .bottom-border {
    border: 1px solid #adb3bc;
  }
  .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}

@media screen and (min-width: 1700px) {
  //footer common styling
  .footer-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }

  //footer main styling
  .footer {
    background-color: #000000;
    .footer-1 {
      padding: 100px 0 30px 0;
      p {
        padding-top: 30px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        width: 81%;
      }
    }
    .footer-2 {
      padding: 100px 0 30px 0;
      h2 {
      }
      .footerlists {
        display: flex;
        .first-list {
        }
        .second-list {
          padding-left: 70px;
        }
      }
    }
    .footer-3 {
      padding: 100px 0 30px 0;
      img {
        padding: 10px 0;
      }
    }
    .bottom-border {
      border: 1px solid #adb3bc;
    }
    .copyright {
      padding-top: 20px;
      text-align: center;
      color: #ffffff;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  //footer common styling
  .footer-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }

  //footer main styling
  .footer {
    background-color: #000000;
    .footer-1 {
      padding: 100px 0 30px 0;
      p {
        padding-top: 30px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        width: 81%;
      }
    }
    .footer-2 {
      padding: 100px 0 30px 0;
      h2 {
      }
      .footerlists {
        display: flex;
        .first-list {
        }
        .second-list {
          padding-left: 70px;
        }
      }
    }
    .footer-3 {
      padding: 100px 0 30px 0;
      img {
        padding: 10px 0;
      }
    }
    .bottom-border {
      border: 1px solid #adb3bc;
    }
    .copyright {
      padding-top: 20px;
      text-align: center;
      color: #ffffff;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  //footer common styling
  .footer-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }

  //footer main styling
  .footer {
    background-color: #000000;
    .footer-1 {
        padding: 30px 0 0 0;
      p {
        padding-top: 30px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        width: 81%;
      }
    }
    .footer-2 {
      padding: 30px 0 0 0;
      h2 {
      }
      .footerlists {
        display: flex;
        .first-list {
        }
        .second-list {
          padding-left: 70px;
        }
      }
    }
    .footer-3 {
        padding: 30px 0 0 0;
      img {
        padding: 10px 0;
      }
    }
    .bottom-border {
      border: 1px solid #adb3bc;
    }
    .copyright {
      padding-top: 20px;
      text-align: center;
      color: #ffffff;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  //footer common styling
  .footer-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    padding-left: 0;
  }
  .style-list {
    list-style: none;
    padding: 0;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }

  //footer main styling
  .footer {
    background-color: #000000;
    .footer-1 {
      padding: 30px 0 0 0;
      p {
        padding-top: 30px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #ffffff;
        width: 100%;
      }
    }
    .footer-2 {
      padding: 30px 0 0 0;
      h2 {
      }
      .footerlists {
        display: flex;
        padding: 0;
        .first-list {
        }
        .second-list {
          padding-left: 70px;
        }
      }
    }
    .footer-3 {
      padding: 30px 0 0 0;
      img {
        padding: 10px 0;
      }

      .app-section {
        padding-top: 50px;
      }
    }
    .bottom-border {
      border: 1px solid #adb3bc;
    }
    .copyright {
      padding-top: 20px;
      text-align: center;
      color: #ffffff;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  //footer common styling
  .footer-heading {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    padding-left: 0;
  }
  .style-list {
    list-style: none;
    padding: 0;
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }

  //footer main styling
  .footer {
    background-color: #000000;
    .footer-1 {
      padding: 30px 0 0 0;
      p {
        padding-top: 30px;
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #ffffff;
        width: 100%;
      }
    }
    .footer-2 {
      padding: 30px 0 0 0;
      h2 {
      }
      .footerlists {
        display: flex;
        padding: 0;
        .first-list {
        }
        .second-list {
          padding-left: 70px;
        }
      }
    }
    .footer-3 {
      padding: 30px 0 0 0;
      img {
        padding: 10px 0;
      }

      .app-section {
        padding-top: 50px;
      }
    }
    .bottom-border {
      border: 1px solid #adb3bc;
    }
    .copyright {
      padding-top: 20px;
      text-align: center;
      color: #ffffff;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
    }
  }
}
