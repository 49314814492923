.change-password {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  height: 100%;

  h6 {
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding-bottom: 24px;
    padding-left: 30px;
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
  }
  p {
    font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
    padding: 30px 30px 0 30px;
  }

  .form-section {
    padding: 30px 53px 0 30px;

    position: relative;
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 0;
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      &:focus {
        box-shadow: none;
        border-bottom: 1px solid #2a7fc5;
      }
    }
    label {
      padding-left: 0;
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
    }
    button {
      background: #0076be;
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      transition: 0.2s ease-in;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      padding: 10px 0;
      margin: 50px 0;
      border: 1px solid #0076be;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        background-color: #ffffff;
        color: #0076be;
        border: 1px solid #0076be;
      }
    }
    .toggle-password {
      position: absolute;
      right: 0;
      top: 33%;
      background: white;
      bottom: 0;
      height: 25px;
      width: 30px;
      cursor: pointer;
      text-align: end;
    }
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label {
      opacity: 0.65;
      transform: scale(0.85) translateY(-0.5rem) translateX(-3px);
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .change-password {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding-bottom: 24px;
      padding-left: 30px;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    }
    p {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #4a4a4a;
      padding: 30px 30px 0 30px;
    }

    .form-section {
      padding: 30px 53px 0 30px;
      position: relative;
      input {
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
        padding-left: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        &:focus {
          box-shadow: none;
          border-bottom: 1px solid #2a7fc5;
        }
      }
      label {
        padding-left: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
      }
      button {
        background: #0076be;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        transition: 0.2s ease-in;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        padding: 10px 0;
        margin: 50px 0;
        border: 1px solid #0076be;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #ffffff;
          color: #0076be;
          border: 1px solid #0076be;
        }
      }
      .toggle-password {
        position: absolute;
        right: 0;
        top: 35%;
        height: 25px;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .change-password {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding-bottom: 24px;
      padding-left: 30px;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    }
    p {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #4a4a4a;
      padding: 30px 30px 0 30px;
    }

    .form-section {
      padding: 30px 53px 0 30px;
      position: relative;
      input {
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0;
        padding-left: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        &:focus {
          box-shadow: none;
          border-bottom: 1px solid #2a7fc5;
        }
      }
      label {
        padding-left: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
      }
      button {
        background: #0076be;
        border-radius: 10px;
        display: block;
        margin: 0 auto;
        transition: 0.2s ease-in;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        padding: 10px 0;
        margin: 50px 0;
        border: 1px solid #0076be;
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background-color: #ffffff;
          color: #0076be;
          border: 1px solid #0076be;
        }
      }
      .toggle-password {
        position: absolute;
        right: 0;
        top: 20%;
        bottom: 0;
        height: 35px;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1198px) {
  .main-changepassword-section {
    .changepassword-col {
      width: 45%;
    }

    .setting-col {
      width: 30% !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-changepassword-section {
    .changepassword-col {
      width: 50%;
    }

    .setting-col {
      width: 40% !important;
    }
  }
}
