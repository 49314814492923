.bg-img-auth {
  background-image: url("../images/loginbannerside.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.continue-wrapper {
  margin: 40px 0;
}
.authbodyarea {
  background-color: #f5f8ff;
  height: 100vh;

  .bagimg {
    width: 100%;
    height: 100%;
  }

  .bagimg img {
    height: 100%;
    width: 100%;
  }

  .authbbox {
    padding: 30px 50px;
    background: #fff;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    border-radius: 25px;
    font-family: $primary-fonts;
    max-width: 580px;
    .file-upload {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }
    .img-upload {
      cursor: pointer;
    }
    > h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #141c4a20;
    }

    > p.para {
      font-size: 18px;
      font-weight: 400;
      font-family: $secondary-fonts;
      padding: 20px 0 10px;
    }

    > a {
      margin: 30px auto 0;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      font-family: $secondary-fonts;
      color: #4a4a4a;
      margin: 10px 0;
    }

    > p a {
      color: #48bf91;
    }

    .already-div {
      margin-top: 40px;
      p {
        margin: 0;
        padding: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #4a4a4a;
        a {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          color: #48bf91;
        }
      }
    }
  }

  .form-floating > label {
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 1rem 0;
  }
  .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    left: -3px;
}
  .inputfeildarea input,
  .form-feild input[type="text"],
  .form-feild input[type="password"],
  .otpinputfeild input,
  .form-feild input[type="email"],
  .form-feild input[type="number"] {
    width: 100%;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    padding: 15px 0 0 1px;
    &:focus {
      border-bottom: 1px solid #1877f2;
    }
  }

  .inputfeildarea {
    position: relative;
  }

  .iconfeild {
    position: absolute;
    bottom: 13px;
    width: 30px;
    right: 12px;
    background: white;
    text-align: end;
  }

  .otpinputfeild input {
    border-bottom: 1px solid #ccc;
    width: 62px !important;
    margin: 0 20px;
  }

  .linkgreen {
    padding: 10px 0;
    font-family: $primary-fonts;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #48bf91;
  }
  .forget-mg {
    margin: 20px 0 40px 0;
  }

  .f-18 {
    font-size: 18px !important;
    color: #4a4a4a !important;
  }

  .mg-tb-40 {
    margin: 40px 0;
  }
  .form-width {
    max-width: 430px;
    margin: 0 auto;
  }
  .google-btn {
    border: 2px solid #adb3bc;
    border-radius: 10px;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    font-family: $primary-fonts;
    font-size: 18px;
    height: 55px;
    line-height: 50px;
    max-width: 430px;
  }

  .google-btn span.icongoogle {
    margin: 0px 10px 0 0;
    position: relative;
    top: -2px;
  }

  .facebook-btn {
    border: 2px solid #1877f2;
    background: #1877f2;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    font-family: $primary-fonts;
    font-size: 18px;
    height: 55px;
    line-height: 50px;
    max-width: 430px;
    margin-bottom: 40px !important;
  }

  .facebook-btn span.icongoogle {
    margin: 0px 10px 0 0;
    position: relative;
    top: -2px;
  }

  a.linkgreen.active {
    color: #48bf91;
  }

  p.pad-lf50 {
    font-family: $secondary-fonts;
    font-weight: 400;
    font-size: 18px;
    max-width: 359px;
    text-align: center !important;
    margin: auto;
    margin-bottom: 50px;
  }

  .optformarea form div {
    justify-content: center;
  }
  .findcontent {
    position: relative;
    padding: 30px 50px;
    text-align: left;
    font-size: 14px;
    color: #4a4a4a;
    font-weight: 300;
    max-width: 375px;
  }

  .findcontenticon {
    position: absolute;
    top: 30px;
    left: 9px;
    font-size: 18px;
    font-weight: 500;
    color: #4a4a4a;
  }
}

@media only screen and (max-width: 992px) {
  .authbodyarea {
    .authbbox {
      width: 100%;
    }
  }
  .mobile-d-none {
    display: none;
  }

  .bgimage {
    background-image: url(../images/loginbannerside.png) no-repeat;
    width: 100%;
    background-size: cover;
  }

  .otpinputfeild input {
    width: 3em !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .authbodyarea {
    .findcontent {
      max-width: 100%;
      padding: 20px 30px 20px 50px;
    }

    .otpinputfeild input {
      width: 40px !important;
    }
  }

  .bg-img-auth {
    // background-image: url("../images/loginbannerside.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    background-image: none !important;
  }
  .authbodyarea .f-18 {
    font-size: 15px !important;
  }
  .authbodyarea .linkgreen {
    font-size: 14px;
  }
  .authbodyarea  p.pad-lf50 {
    font-size: 15px;
    max-width: 359px;
    margin-bottom: 50px;
  }
  .authbodyarea .authbbox > p.para {
    font-size: 15px;
}
.authbodyarea .authbbox {
  padding: 30px 50px;
  width: 80%;
  margin: 0 auto;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

}
