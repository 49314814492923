.confirmed-order {
  .order-completed-container {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 40px;
    border-radius: 20px;

    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #000000;
      padding-top: 29px;
    }

    .skip-div {
      p {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #000000;
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        padding-bottom: 20px;
        span {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #0076be;
          float: right;
        }
      }
    }
    .rate {
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #4a4a4a;
      padding-top: 18px;
      margin: 0;
    }
    .start-rating-section {
      padding-top: 40px;
      justify-content: center;
      display: flex;
      padding-bottom: 40px;
      .stars {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;
      }

      .stars__star-icon {
        stroke: #efce4a;
        stroke-width: 2px;
        fill: transparent;
        transition: 0.1s all;
      }

      .stars__star {
        width: 75px;
        height: 75px;
        position: relative;
        cursor: pointer;
        margin: 10px;
      }

      .stars__checkbox {
        position: absolute;
        top: -9999px;
        opacity: 0;
        width: 0;
        height: 0;
      }

      .stars__star:hover > .stars__star-icon {
        fill: #efce4a;
      }

      .stars__star:hover ~ .stars__star > .stars__star-icon {
        fill: #efce4a;
      }

      .stars__checkbox:checked + .stars__star > .stars__star-icon {
        fill: #efce4a;
      }

      .stars__checkbox:checked ~ .stars__star > .stars__star-icon {
        fill: #efce4a;
      }
    }

    .text-msg-div {
      padding: 0 60px;
      justify-content: center;

      textarea {
        background: linear-gradient(0deg, #f4f9fb, #f4f9fb), #f6f6f6;
        border-radius: 10px;
        min-height: 150px;
        padding: 10px;
        resize: none;
      }

      .submit-btn {
        padding-top: 40px;
        button {
          padding: 14px 155px;
          margin: 0 auto;
          background: #0076be;
          border-radius: 10px;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          text-align: center;
          color: #ffffff;
          transition: 0.2s ease-in;
          &:hover {
            background-color: white;
            border: 1px solid #0076be;
            color: #0076be;
          }
        }
      }
    }
  }
}
