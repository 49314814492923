.contact-us {
  background: #ffffff;
  border-radius: 20px;

  .contact-information {
    background: #48bf91;
    border-radius: 25px;
    padding: 50px 40px;
    max-width: 567px;

    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      color: #ffffff;
      text-align: left;
    }
    p {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      padding: 10px 0 36px 0;
    }
    .border-line {
      opacity: 0.5;
      border: 1px solid #ffffff;
    }
    ul {
      padding: 0;
      margin-top: 40px;
      li {
        padding: 5px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0 0 36px 0;

        i {
          margin: 0 20px 0 0;
        }
      }
    }
  }

  .contact-form {
    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      color: #000000;
    }
    p {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #4a4a4a;
    }

    form {
      input {
        border: none;
        border-bottom: 1px solid #adb3bc;
        border-radius: 0;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
        padding-left: 0;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid #2a7fc5;
        }
      }
      label {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
      }
      textarea {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4a4a4a;
        border: none;
        border-bottom: 1px solid #adb3bc;
        border-radius: 0;
        height: 60px;
        padding-left: 0;

        &:focus {
          box-shadow: none;
          border-bottom: 1px solid #2a7fc5;
        }
      }
      .submitbtn {
        margin-top: 50px !important;
      }
    }
  }
}



@media screen and (min-width: 1700px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;

    .contact-information {
      background: #48bf91;
      border-radius: 25px;
      padding: 50px 40px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
        text-align: left;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        padding: 10px 0 36px 0;
      }
      .border-line {
        opacity: 0.5;
        border: 1px solid #ffffff;
      }
      ul {
        padding: 0;
        margin-top: 40px;
        li {
          padding: 5px;
          font-family:  $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0 0 36px 0;

          i {
            margin: 0 20px 0 0;
          }
        }
      }
    }

    .contact-form {
      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        color: #000000;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #4a4a4a;
      }

      form {
        input {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          padding-left: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }

        textarea {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          padding-left: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
        label {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        textarea {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;

    .contact-information {
      background: #48bf91;
      border-radius: 25px;
      padding: 50px 40px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
        text-align: left;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        padding: 10px 0 36px 0;
      }
      .border-line {
        opacity: 0.5;
        border: 1px solid #ffffff;
      }
      ul {
        padding: 0;
        margin-top: 40px;
        li {
          padding: 5px;
          font-family:  $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0 0 36px 0;

          i {
            margin: 0 20px 0 0;
          }
        }
      }
    }

    .contact-form {
      margin-top: 10px;
      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        color: #000000;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #4a4a4a;
      }

      form {
        input {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
        label {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        textarea {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .contact-us-padding {
      padding-left: 100px !important;

  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;

    .contact-information {
      background: #48bf91;
      border-radius: 25px;
      padding: 50px 40px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
        text-align: left;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        padding: 10px 0 36px 0;
      }
      .border-line {
        opacity: 0.5;
        border: 1px solid #ffffff;
      }
      ul {
        padding: 0;
        margin-top: 40px;
        li {
          padding: 5px;
          font-family:  $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0 0 36px 0;

          i {
            margin: 0 20px 0 0;
          }
        }
      }
    }

    .contact-form {
      margin-top: 50px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        color: #000000;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #4a4a4a;
      }

      form {
        input {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
        label {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        textarea {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;

    .contact-information {
      background: #48bf91;
      border-radius: 25px;
      padding: 50px 40px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        color: #ffffff;
        text-align: left;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        padding: 10px 0 36px 0;
      }
      .border-line {
        opacity: 0.5;
        border: 1px solid #ffffff;
      }
      ul {
        padding: 0;
        margin-top: 40px;
        li {
          padding: 5px;
          font-family:  $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: #ffffff;
          list-style: none;
          display: flex;
          align-items: center;
          padding: 0 0 36px 0;

          i {
            margin: 0 20px 0 0;
          }
        }
      }
    }

    .contact-form {
      margin-top: 50px;

      h2 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        color: #000000;
      }
      p {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #4a4a4a;
      }

      form {
        input {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
        label {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        textarea {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .contact-us {
    .contact-information {
      padding: 50px 40px;

      h2 {
        font-size: 20px;
        text-align: left;
      }
      p {
        font-size: 16px;
        padding: 10px 0 36px 0;
      }
      .border-line {
        opacity: 0.5;
      }
      ul {
        padding: 0;
        margin-top: 40px;
        li {
          padding: 5px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          padding: 0 0 36px 0;

          i {
            margin: 0 20px 0 0;
          }
        }
      }
    }

    .contact-form {
      margin-top: 50px;

      h2 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }

      form {
        input {
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
        label {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
        }
        textarea {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4a4a4a;
          border: none;
          border-bottom: 1px solid #adb3bc;
          border-radius: 0;
          height: 30px !important;

          &:focus {
            box-shadow: none;
            border-bottom: 1px solid #2a7fc5;
          }
        }
      }
    }
  }
}

// Bug Fixes media querry
@media (max-width: 1050px) {
  .contact-us {
    .contact-information {
      max-width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .contact-information {
    ul {
      padding: 0;
      margin-top: 40px;
      li {
        padding: 5px;
        font-family:  $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px !important;
        line-height: 28px;
        color: #ffffff;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0 0 36px 0;

        i {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
