.main-store-chat {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 36px;
  padding-right: 36px;
  height: 100%;
  padding: 22px 24px 22px 24px;
  position: relative;
  .heading-div {
    position: relative;
    h2 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #000000;
    }

    svg {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .day-div {
    p {
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #adb3bc;
    }
  }

  .main-chat-container {
    height: 100%;
    max-height: 540px;
    overflow-y: auto;
    .sender-chat-div {
      margin-top: 19px;
      .img-text {
        display: flex;
        align-items: center;
        p {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          background: #f4f9fb;
          border-radius: 25px 25px 25px 0px;
          padding: 22px 74px 22px 22px;
          margin-left: 17px;
          margin-bottom: 0;
        }
      }
      .time {
        margin-bottom: 0;
        text-align: end;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #979797;
        margin-top: 10px;
      }
    }
    .receiver-chat-div {
      margin-top: 31px;
      .img-text {
        display: flex;
        align-items: center;
        p {
          font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
          background: #f4f9fb;
          border-radius: 25px 25px 0px 25px;
          padding: 22px 74px 22px 22px;
          margin-right: 17px;
          margin-bottom: 0;
        }
      }
      .time {
        margin-bottom: 0;
        text-align: start;
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #979797;
        margin-top: 10px;
      }
    }
  }

  // .msg-type-div {
  //   background: #f4f9fb;
  //   border: 1px solid #f0f0f0;
  //   border-radius: 10px;
  //   display: flex;
  //   align-items: center;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   margin-top: 31px;
  //   justify-content: space-between;
  //   position: relative;
  //   textarea {
  //     background: transparent;
  //     display: block;
  //     width: 95%;
  //     border: none;
  //     height: 50px;
  //     line-height: 18px;
  //     resize: none;
  //     transform: translateY(15px);

  //     &::placeholder {

  //     font-family: $secondary-fonts;
  //       font-style: normal;
  //       font-weight: 400;
  //       font-size: 14px;
  //       color: #000000;
  //     }
  //     &:focus{
  //       border: none;
  //       box-shadow: none;
  //       outline: none;
  //     }
  //     &::-webkit-scrollbar {
  //       display: none;
  //     }
  //   }
  // }
  .message-container {
    position: relative;
  }
  .message-wrapper {
    background-color: #f4f9fb;
    border-radius: 10px;
    .profile-wrapper {
      left: 1rem;
      top: 10px;
      min-width: 35px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
    }
    .btn-send {
      padding: 0;
      background: transparent;
      &:hover,
      &:focus {
        background: transparent;
      }
      // .icon {
      //   color: $color-dark-grey;
      //   font-size: 1.5rem;
      // }
    }
    input,
    .form-control {
      border: none;
      background: transparent;
      // padding-left: 55px;
      resize: none;
      font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      padding: 15px 10px;

      &:hover,
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
      &::placeholder {
        font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .input-group {
      border: none;
      &:focus {
        border: none;
      }
    }
    .input-group-text {
      border: none;
      background: transparent;
      cursor: pointer;

      svg {
        cursor: pointer;
      }
    }
  }

  .main-img-preview-div {
    position: absolute;
    bottom: 81px;
    width: 88%;

    .sub-img-preview-div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-div {
        display: flex;
        align-items: center;
        .img-chat-div {
          width: 60px;
          height: 40px;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: center;
          }
        }
        .img-name-div {
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            width: 100px;
            margin-left: 5px;
          }
          // span {
          //   margin-left: -1px;
          // }
        }
      }
      .close-btn-div {
        button {
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
