@media screen and (max-width: 992px){
    .second-navbar-container{
        display: flex;
        justify-content: space-between;
        nav{
            ul{
                display: block;
                li{
                    padding: 15px 0;
                }
            }
            form{
                button{
                    display: none;
                }
            }
        }
        .filter-button{
            padding-bottom: 0.5rem;
        
        .blue-button {
            color: #fff;
            background: #0076BE;
            font-family: $primary-fonts;
            border-radius: 10px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            border-radius: 10px;
            padding: 10px 15px;
            border-color: #0076BE;
            border: 0;
            float: right;
            margin: 18px 30px 0 0;
         &:hover{
            color: #FFFFFF;
         }
            
        }
        svg{
            margin-right: 10px;
        }
        }

    }
}