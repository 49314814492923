.trendingproduct{

    .trending-nav-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        h2{
          font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            color: #000000;
            margin-bottom: 10px;
        }
    }
    .product-box{
        margin-bottom: 20px;
    }
}
/* Column 5  */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}
@media (min-width: 768px) {
.col-sm-15 {
      width: 20%;
      float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
      width: 20%;
      float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
      width: 20%;
      float: left;
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
    .view-button{
        font-size: 16px;    
    }
    
    .slider-btn{
        padding: 14px 18px;
    }
    .trendingproduct{
        .trending-nav-heading{
            display: block;
            justify-content: space-between;
            align-items: center;
        
            h2{
                font-size: 24px;
            }
            .slider-btns{
                display: flex;
                align-items: center;
                justify-content: start;
            
                .view-button{
                    margin-right: 30px;
                }
                .slider-arrow-btns{
                   
                    .slider-btn:not(:first-child){
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    
}
 
 