.main-search-container {
//   width: 892px;
//   height: 75px;


  .upper-searchbar {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    .searchicon-inputdiv {
      display: flex;
      align-items: center;
      width: 100%;

      input {
      font-family: $secondary-fonts;
        background: transparent;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }
    }
    .searchbtn-closediv {
      display: flex;
      align-items: center;
      button {
        background: #0076be;
        border-radius: 10px;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
        // padding: 15px 0;
        min-width: 126px;
        // min-height: 45px;
        line-height: 45px;
        border: none;
        margin-right: 20px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .bottom-searchbar {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-top: 10px;
    padding-bottom: 36px;

    .search-tabs {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;

      .nav-pills {
        .nav-item {
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          .nav-link {
            color: #000000;
          }
          .active {
            background: transparent;
            color: #48bf91;
            border-bottom: 5px solid #48bf91;
            border-radius: 0;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
    .bottom-borderr {
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      margin-top: -29px;
      
    }
    .search-results-div {
        

      .tab-content {
        .all-div {
          padding-left: 20px;
          padding-right: 20px;
          .heading-div {
            margin-top: 21px;

            h6 {
              font-family: $primary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #000000;
            }
          }
          .search-results-div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 19px;
            p {
              margin: 0;
            font-family: $secondary-fonts;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              color: #4a4a4a;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px){
  .main-search-container{
    .upper-searchbar{
      .searchbtn-closediv{
        button{
          background: #0076be;
          border-radius: 10px;
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          text-align: center;
          color: #ffffff;
          min-width: 90px;
          /* min-height: 25px; */
          line-height: 33px;
          height: 33px;
          border: none;
          margin-right: 10px;
        }
      }
    }
  }
}