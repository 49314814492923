@charset "UTF-8";
@font-face {
  font-family: "RocknRoll One";
  src: url("../fonts/RocknRollOne-Regular.woff2") format("woff2"), url("../fonts/RocknRollOne-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SFProText-Medium.woff2") format("woff2"), url("../fonts/SFProText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SFProText-Regular.woff2") format("woff2"), url("../fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=color.css.map */
/*
********************************************
* Global Color Theme
********************************************
*/
/*
********************************************
* Scrollbar Style
********************************************
*/
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

/*
********************************************
* Number Input Style
********************************************
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
********************************************
* Global Style
********************************************
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF Pro Text", sans-serif;
}

dl,
ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.text-red {
  color: red;
  font-size: 12px;
}

input[type=number] {
  -moz-appearance: textfield;
}

.btn {
  box-shadow: none !important;
}
.btn:hover, .btn:focus {
  box-shadow: none !important;
}

input,
.form-control {
  font-size: 12px;
  height: 55px;
  border-radius: 10px;
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  outline: none;
}
input::placeholder,
.form-control::placeholder {
  color: #4a4a4a;
  font-weight: 300;
}
input:hover, input:focus,
.form-control:hover,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

.input-group {
  border-radius: 10px;
  border: 1px solid #adb3bc;
  transition: all 0.5s ease;
}
.input-group:hover, .input-group:focus {
  border: 1px solid #adb3bc;
}
.input-group .input-group-text {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
}

/*
********************************************
* Theme Font Size, Family And Weight 
********************************************
*/
h2,
.h2 {
  font-size: 36px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 20px;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 18px;
  font-weight: 400;
}

p,
span,
.form-label {
  font-size: 14px;
  font-weight: 400;
}

a,
.nav-link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

small {
  font-size: 12px;
  font-weight: 400;
}

ul li {
  font-size: 12px;
}

.button.btn-solid-blue,
a.button.btn-solid-blue,
button.button.btn-solid-blue {
  font-family: "RocknRoll One", sans-serif;
  max-width: 430px;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: #000;
  outline: none;
  box-shadow: none;
  transition: all 0.5s ease;
  background-color: #0076be;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
}
.button.btn-solid-blue:hover, .button.btn-solid-blue:focus, .button.btn-solid-blue:focus-within,
a.button.btn-solid-blue:hover,
a.button.btn-solid-blue:focus,
a.button.btn-solid-blue:focus-within,
button.button.btn-solid-blue:hover,
button.button.btn-solid-blue:focus,
button.button.btn-solid-blue:focus-within {
  color: #000000;
  background: transparent;
  border: 2px solid #0076be;
  color: #0076be;
  box-shadow: none;
  outline: none;
}
.button.btn-outline-blue,
a.button.btn-outline-blue,
button.button.btn-outline-blue {
  border: 2px solid #0076be;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: none;
  transition: all 0.5s ease;
}
.button.btn-outline-blue:hover, .button.btn-outline-blue:focus, .button.btn-outline-blue:focus-within,
a.button.btn-outline-blue:hover,
a.button.btn-outline-blue:focus,
a.button.btn-outline-blue:focus-within,
button.button.btn-outline-blue:hover,
button.button.btn-outline-blue:focus,
button.button.btn-outline-blue:focus-within {
  background-color: #0076be;
  box-shadow: none;
  outline: none;
}
.button.btn-solid-green,
a.button.btn-solid-green,
button.button.btn-solid-green {
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  max-width: 430px;
  border: 2px solid transparent;
  background-color: #000;
  outline: none;
  box-shadow: none;
  transition: all 0.5s ease;
  background-color: #48bf91;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
}
.button.btn-solid-green:hover, .button.btn-solid-green:focus, .button.btn-solid-green:focus-within,
a.button.btn-solid-green:hover,
a.button.btn-solid-green:focus,
a.button.btn-solid-green:focus-within,
button.button.btn-solid-green:hover,
button.button.btn-solid-green:focus,
button.button.btn-solid-green:focus-within {
  color: #000000;
  background: transparent;
  border: 2px solid #48bf91;
  color: #48bf91;
  box-shadow: none;
  outline: none;
}
.button.btn-outline-green,
a.button.btn-outline-green,
button.button.btn-outline-green {
  border: 2px solid #48bf91;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: none;
  transition: all 0.5s ease;
}
.button.btn-outline-green:hover, .button.btn-outline-green:focus, .button.btn-outline-green:focus-within,
a.button.btn-outline-green:hover,
a.button.btn-outline-green:focus,
a.button.btn-outline-green:focus-within,
button.button.btn-outline-green:hover,
button.button.btn-outline-green:focus,
button.button.btn-outline-green:focus-within {
  background-color: #48bf91;
  box-shadow: none;
  outline: none;
}

button.button.btn-solid-blue {
  line-height: unset;
}

.home-filter-button {
  text-decoration: none;
  color: #fff;
  background: #0076be;
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  /* padding: 10px 15px; */
  border-color: #0076be;
  border: 0;
  float: right;
  text-decoration: none;
  width: 126px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}
.home-filter-button svg {
  margin-right: 10px;
}
.home-filter-button:hover {
  color: #fff;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-right-30 {
  margin-right: 30px !important;
}

.bg-img-auth {
  background-image: url("../images/loginbannerside.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.continue-wrapper {
  margin: 40px 0;
}

.authbodyarea {
  background-color: #f5f8ff;
  height: 100vh;
}
.authbodyarea .bagimg {
  width: 100%;
  height: 100%;
}
.authbodyarea .bagimg img {
  height: 100%;
  width: 100%;
}
.authbodyarea .authbbox {
  padding: 30px 50px;
  background: #fff;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  border-radius: 25px;
  font-family: "RocknRoll One", sans-serif;
  max-width: 580px;
}
.authbodyarea .authbbox .file-upload {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.authbodyarea .authbbox .img-upload {
  cursor: pointer;
}
.authbodyarea .authbbox > h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(20, 28, 74, 0.1254901961);
}
.authbodyarea .authbbox > p.para {
  font-size: 18px;
  font-weight: 400;
  font-family: "SF Pro Text", sans-serif;
  padding: 20px 0 10px;
}
.authbodyarea .authbbox > a {
  margin: 30px auto 0;
}
.authbodyarea .authbbox > p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  color: #4a4a4a;
  margin: 10px 0;
}
.authbodyarea .authbbox > p a {
  color: #48bf91;
}
.authbodyarea .authbbox .already-div {
  margin-top: 40px;
}
.authbodyarea .authbbox .already-div p {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4a4a4a;
}
.authbodyarea .authbbox .already-div p a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #48bf91;
}
.authbodyarea .form-floating > label {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 1rem 0;
}
.authbodyarea .form-floating > .form-control:not(:placeholder-shown) ~ label, .authbodyarea .form-floating > .form-select ~ label {
  left: -3px;
}
.authbodyarea .inputfeildarea input,
.authbodyarea .form-feild input[type=text],
.authbodyarea .form-feild input[type=password],
.authbodyarea .otpinputfeild input,
.authbodyarea .form-feild input[type=email],
.authbodyarea .form-feild input[type=number] {
  width: 100%;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  padding: 15px 0 0 1px;
}
.authbodyarea .inputfeildarea input:focus,
.authbodyarea .form-feild input[type=text]:focus,
.authbodyarea .form-feild input[type=password]:focus,
.authbodyarea .otpinputfeild input:focus,
.authbodyarea .form-feild input[type=email]:focus,
.authbodyarea .form-feild input[type=number]:focus {
  border-bottom: 1px solid #1877f2;
}
.authbodyarea .inputfeildarea {
  position: relative;
}
.authbodyarea .iconfeild {
  position: absolute;
  bottom: 13px;
  width: 30px;
  right: 12px;
  background: white;
  text-align: end;
}
.authbodyarea .otpinputfeild input {
  border-bottom: 1px solid #ccc;
  width: 62px !important;
  margin: 0 20px;
}
.authbodyarea .linkgreen {
  padding: 10px 0;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #48bf91;
}
.authbodyarea .forget-mg {
  margin: 20px 0 40px 0;
}
.authbodyarea .f-18 {
  font-size: 18px !important;
  color: #4a4a4a !important;
}
.authbodyarea .mg-tb-40 {
  margin: 40px 0;
}
.authbodyarea .form-width {
  max-width: 430px;
  margin: 0 auto;
}
.authbodyarea .google-btn {
  border: 2px solid #adb3bc;
  border-radius: 10px;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  font-family: "RocknRoll One", sans-serif;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
  max-width: 430px;
}
.authbodyarea .google-btn span.icongoogle {
  margin: 0px 10px 0 0;
  position: relative;
  top: -2px;
}
.authbodyarea .facebook-btn {
  border: 2px solid #1877f2;
  background: #1877f2;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  font-family: "RocknRoll One", sans-serif;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
  max-width: 430px;
  margin-bottom: 40px !important;
}
.authbodyarea .facebook-btn span.icongoogle {
  margin: 0px 10px 0 0;
  position: relative;
  top: -2px;
}
.authbodyarea a.linkgreen.active {
  color: #48bf91;
}
.authbodyarea p.pad-lf50 {
  font-family: "SF Pro Text", sans-serif;
  font-weight: 400;
  font-size: 18px;
  max-width: 359px;
  text-align: center !important;
  margin: auto;
  margin-bottom: 50px;
}
.authbodyarea .optformarea form div {
  justify-content: center;
}
.authbodyarea .findcontent {
  position: relative;
  padding: 30px 50px;
  text-align: left;
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 300;
  max-width: 375px;
}
.authbodyarea .findcontenticon {
  position: absolute;
  top: 30px;
  left: 9px;
  font-size: 18px;
  font-weight: 500;
  color: #4a4a4a;
}

@media only screen and (max-width: 992px) {
  .authbodyarea .authbbox {
    width: 100%;
  }
  .mobile-d-none {
    display: none;
  }
  .bgimage {
    background-image: url(../images/loginbannerside.png) no-repeat;
    width: 100%;
    background-size: cover;
  }
  .otpinputfeild input {
    width: 3em !important;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .authbodyarea .findcontent {
    max-width: 100%;
    padding: 20px 30px 20px 50px;
  }
  .authbodyarea .otpinputfeild input {
    width: 40px !important;
  }
  .bg-img-auth {
    background-image: none !important;
  }
  .authbodyarea .f-18 {
    font-size: 15px !important;
  }
  .authbodyarea .linkgreen {
    font-size: 14px;
  }
  .authbodyarea p.pad-lf50 {
    font-size: 15px;
    max-width: 359px;
    margin-bottom: 50px;
  }
  .authbodyarea .authbbox > p.para {
    font-size: 15px;
  }
  .authbodyarea .authbbox {
    padding: 30px 50px;
    width: 80%;
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.contact-us {
  background: #ffffff;
  border-radius: 20px;
}
.contact-us .contact-information {
  background: #48bf91;
  border-radius: 25px;
  padding: 50px 40px;
  max-width: 567px;
}
.contact-us .contact-information h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #ffffff;
  text-align: left;
}
.contact-us .contact-information p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 0 36px 0;
}
.contact-us .contact-information .border-line {
  opacity: 0.5;
  border: 1px solid #ffffff;
}
.contact-us .contact-information ul {
  padding: 0;
  margin-top: 40px;
}
.contact-us .contact-information ul li {
  padding: 5px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0 0 36px 0;
}
.contact-us .contact-information ul li i {
  margin: 0 20px 0 0;
}
.contact-us .contact-form h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #000000;
}
.contact-us .contact-form p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
}
.contact-us .contact-form form input {
  border: none;
  border-bottom: 1px solid #adb3bc;
  border-radius: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
  padding-left: 0;
}
.contact-us .contact-form form input:focus {
  box-shadow: none;
  border-bottom: 1px solid #2a7fc5;
}
.contact-us .contact-form form label {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}
.contact-us .contact-form form textarea {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
  border: none;
  border-bottom: 1px solid #adb3bc;
  border-radius: 0;
  height: 60px;
  padding-left: 0;
}
.contact-us .contact-form form textarea:focus {
  box-shadow: none;
  border-bottom: 1px solid #2a7fc5;
}
.contact-us .contact-form form .submitbtn {
  margin-top: 50px !important;
}

@media screen and (min-width: 1700px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;
  }
  .contact-us .contact-information {
    background: #48bf91;
    border-radius: 25px;
    padding: 50px 40px;
  }
  .contact-us .contact-information h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    text-align: left;
  }
  .contact-us .contact-information p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 0 36px 0;
  }
  .contact-us .contact-information .border-line {
    opacity: 0.5;
    border: 1px solid #ffffff;
  }
  .contact-us .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-us .contact-information ul li {
    padding: 5px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-us .contact-information ul li i {
    margin: 0 20px 0 0;
  }
  .contact-us .contact-form h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #000000;
  }
  .contact-us .contact-form p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    padding-left: 0;
  }
  .contact-us .contact-form form input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form textarea {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    padding-left: 0;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form label {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form textarea {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;
  }
  .contact-us .contact-information {
    background: #48bf91;
    border-radius: 25px;
    padding: 50px 40px;
  }
  .contact-us .contact-information h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    text-align: left;
  }
  .contact-us .contact-information p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 0 36px 0;
  }
  .contact-us .contact-information .border-line {
    opacity: 0.5;
    border: 1px solid #ffffff;
  }
  .contact-us .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-us .contact-information ul li {
    padding: 5px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-us .contact-information ul li i {
    margin: 0 20px 0 0;
  }
  .contact-us .contact-form {
    margin-top: 10px;
  }
  .contact-us .contact-form h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #000000;
  }
  .contact-us .contact-form p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form label {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form textarea {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1700px) {
  .contact-us-padding {
    padding-left: 100px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;
  }
  .contact-us .contact-information {
    background: #48bf91;
    border-radius: 25px;
    padding: 50px 40px;
  }
  .contact-us .contact-information h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    text-align: left;
  }
  .contact-us .contact-information p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 0 36px 0;
  }
  .contact-us .contact-information .border-line {
    opacity: 0.5;
    border: 1px solid #ffffff;
  }
  .contact-us .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-us .contact-information ul li {
    padding: 5px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-us .contact-information ul li i {
    margin: 0 20px 0 0;
  }
  .contact-us .contact-form {
    margin-top: 50px;
  }
  .contact-us .contact-form h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #000000;
  }
  .contact-us .contact-form p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form label {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form textarea {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .contact-us {
    background: #ffffff;
    border-radius: 20px;
  }
  .contact-us .contact-information {
    background: #48bf91;
    border-radius: 25px;
    padding: 50px 40px;
  }
  .contact-us .contact-information h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #ffffff;
    text-align: left;
  }
  .contact-us .contact-information p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    padding: 10px 0 36px 0;
  }
  .contact-us .contact-information .border-line {
    opacity: 0.5;
    border: 1px solid #ffffff;
  }
  .contact-us .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-us .contact-information ul li {
    padding: 5px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-us .contact-information ul li i {
    margin: 0 20px 0 0;
  }
  .contact-us .contact-form {
    margin-top: 50px;
  }
  .contact-us .contact-form h2 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #000000;
  }
  .contact-us .contact-form p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form label {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form textarea {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .contact-us .contact-information {
    padding: 50px 40px;
  }
  .contact-us .contact-information h2 {
    font-size: 20px;
    text-align: left;
  }
  .contact-us .contact-information p {
    font-size: 16px;
    padding: 10px 0 36px 0;
  }
  .contact-us .contact-information .border-line {
    opacity: 0.5;
  }
  .contact-us .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-us .contact-information ul li {
    padding: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-us .contact-information ul li i {
    margin: 0 20px 0 0;
  }
  .contact-us .contact-form {
    margin-top: 50px;
  }
  .contact-us .contact-form h2 {
    font-size: 30px;
  }
  .contact-us .contact-form p {
    font-size: 15px;
  }
  .contact-us .contact-form form input {
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .contact-us .contact-form form label {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .contact-us .contact-form form textarea {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    border: none;
    border-bottom: 1px solid #adb3bc;
    border-radius: 0;
    height: 30px !important;
  }
  .contact-us .contact-form form textarea:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
}
@media (max-width: 1050px) {
  .contact-us .contact-information {
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  .contact-information ul {
    padding: 0;
    margin-top: 40px;
  }
  .contact-information ul li {
    padding: 5px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 28px;
    color: #ffffff;
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0 0 36px 0;
  }
  .contact-information ul li i {
    margin: 0 20px 0 0;
  }
}
@font-face {
  font-family: "RocknRoll One";
  src: url("../fonts/RocknRollOne-Regular.woff2") format("woff2"), url("../fonts/RocknRollOne-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SFProText-Medium.woff2") format("woff2"), url("../fonts/SFProText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("../fonts/SFProText-Regular.woff2") format("woff2"), url("../fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/*# sourceMappingURL=color.css.map */
/*
********************************************
* Global Color Theme
********************************************
*/
/*
********************************************
* Scrollbar Style
********************************************
*/
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
}

::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

/*
********************************************
* Number Input Style
********************************************
*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
********************************************
* Global Style
********************************************
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF Pro Text", sans-serif;
}

dl,
ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.text-red {
  color: red;
  font-size: 12px;
}

input[type=number] {
  -moz-appearance: textfield;
}

.btn {
  box-shadow: none !important;
}
.btn:hover, .btn:focus {
  box-shadow: none !important;
}

input,
.form-control {
  font-size: 12px;
  height: 55px;
  border-radius: 10px;
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  outline: none;
}
input::placeholder,
.form-control::placeholder {
  color: #4a4a4a;
  font-weight: 300;
}
input:hover, input:focus,
.form-control:hover,
.form-control:focus {
  box-shadow: none;
  outline: none;
}

.input-group {
  border-radius: 10px;
  border: 1px solid #adb3bc;
  transition: all 0.5s ease;
}
.input-group:hover, .input-group:focus {
  border: 1px solid #adb3bc;
}
.input-group .input-group-text {
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
}

/*
********************************************
* Theme Font Size, Family And Weight 
********************************************
*/
h2,
.h2 {
  font-size: 36px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 20px;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 18px;
  font-weight: 400;
}

p,
span,
.form-label {
  font-size: 14px;
  font-weight: 400;
}

a,
.nav-link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

small {
  font-size: 12px;
  font-weight: 400;
}

ul li {
  font-size: 12px;
}

.button.btn-solid-blue,
a.button.btn-solid-blue,
button.button.btn-solid-blue {
  font-family: "RocknRoll One", sans-serif;
  max-width: 430px;
  border-radius: 10px;
  border: 2px solid transparent;
  background-color: #000;
  outline: none;
  box-shadow: none;
  transition: all 0.5s ease;
  background-color: #0076be;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
}
.button.btn-solid-blue:hover, .button.btn-solid-blue:focus, .button.btn-solid-blue:focus-within,
a.button.btn-solid-blue:hover,
a.button.btn-solid-blue:focus,
a.button.btn-solid-blue:focus-within,
button.button.btn-solid-blue:hover,
button.button.btn-solid-blue:focus,
button.button.btn-solid-blue:focus-within {
  color: #000000;
  background: transparent;
  border: 2px solid #0076be;
  color: #0076be;
  box-shadow: none;
  outline: none;
}
.button.btn-outline-blue,
a.button.btn-outline-blue,
button.button.btn-outline-blue {
  border: 2px solid #0076be;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: none;
  transition: all 0.5s ease;
}
.button.btn-outline-blue:hover, .button.btn-outline-blue:focus, .button.btn-outline-blue:focus-within,
a.button.btn-outline-blue:hover,
a.button.btn-outline-blue:focus,
a.button.btn-outline-blue:focus-within,
button.button.btn-outline-blue:hover,
button.button.btn-outline-blue:focus,
button.button.btn-outline-blue:focus-within {
  background-color: #0076be;
  box-shadow: none;
  outline: none;
}
.button.btn-solid-green,
a.button.btn-solid-green,
button.button.btn-solid-green {
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  max-width: 430px;
  border: 2px solid transparent;
  background-color: #000;
  outline: none;
  box-shadow: none;
  transition: all 0.5s ease;
  background-color: #48bf91;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  height: 55px;
  line-height: 50px;
}
.button.btn-solid-green:hover, .button.btn-solid-green:focus, .button.btn-solid-green:focus-within,
a.button.btn-solid-green:hover,
a.button.btn-solid-green:focus,
a.button.btn-solid-green:focus-within,
button.button.btn-solid-green:hover,
button.button.btn-solid-green:focus,
button.button.btn-solid-green:focus-within {
  color: #000000;
  background: transparent;
  border: 2px solid #48bf91;
  color: #48bf91;
  box-shadow: none;
  outline: none;
}
.button.btn-outline-green,
a.button.btn-outline-green,
button.button.btn-outline-green {
  border: 2px solid #48bf91;
  padding: 15px 0;
  border-radius: 10px;
  box-shadow: none;
  transition: all 0.5s ease;
}
.button.btn-outline-green:hover, .button.btn-outline-green:focus, .button.btn-outline-green:focus-within,
a.button.btn-outline-green:hover,
a.button.btn-outline-green:focus,
a.button.btn-outline-green:focus-within,
button.button.btn-outline-green:hover,
button.button.btn-outline-green:focus,
button.button.btn-outline-green:focus-within {
  background-color: #48bf91;
  box-shadow: none;
  outline: none;
}

button.button.btn-solid-blue {
  line-height: unset;
}

.home-filter-button {
  text-decoration: none;
  color: #fff;
  background: #0076be;
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  /* padding: 10px 15px; */
  border-color: #0076be;
  border: 0;
  float: right;
  text-decoration: none;
  width: 126px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}
.home-filter-button svg {
  margin-right: 10px;
}
.home-filter-button:hover {
  color: #fff;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-right-30 {
  margin-right: 30px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "SF Pro Text", sans-serif;
  background-color: #f5f8ff;
  overflow-x: hidden;
}

body.active {
  overflow: hidden;
}
body.active::before {
  content: "";
  background: rgba(16, 16, 16, 0.5);
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.mainpadding {
  padding: 0 50px;
}

.main-margin {
  margin: 32px 60px;
}

h2,
h3 {
  font-family: "RocknRoll One", sans-serif;
}

/**************************************8
            Header
*****************************************/
.header .black {
  background: #000000;
  padding: 15px 0;
}
.header .profilelist {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0px;
}
.header .profilelist li {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  margin: 0 5px; /* border-radius: 150px; */
}
.header .layericonbg {
  background: #1c1c1c;
  width: 40px;
  height: 40px;
  border-radius: 150px;
  line-height: 36px;
}
.header nav ul {
  display: flex;
}
.header nav ul li {
  padding: 20px 107px 28px 0;
}
.header nav ul li a {
  font-weight: 500;
  font-size: 18px;
  color: #4a4a4a;
  font-family: "SF Pro Text", sans-serif;
}
.header nav ul li a:hover {
  color: #0076be;
}
.header nav ul li a.active {
  color: #0076be;
  font-weight: 600;
}

section.newfeature-box {
  margin: 25px auto;
}

.featurebox .card {
  border: 0px;
  background-color: transparent;
}
.featurebox .card-img-overlay {
  top: initial;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 0px 0px 20px 20px;
}
.featurebox .card h5.card-title {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  color: #fff;
}

.storeHeading-title h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #000;
  margin: 0;
  align-items: center;
}

.slider-btns {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.viewall-button {
  background: #0076be;
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  width: 126px;
  height: 45px;
  text-align: center;
  margin: 0 20px;
}

.storeboxesarea {
  width: 100%;
  display: inline-block;
  background: #fff;
  padding: 6px;
  border-radius: 17px;
  /* margin-left: 25px; */
}

.storeboxesarea + .storeboxesarea {
  margin-left: 20px;
}

.storeboxesarea img {
  width: 100%;
  border-radius: 5px;
}

.storeboxesarea h3 {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-family: "SF Pro Text", sans-serif;
  font-size: 16px;
  padding: 15px 6px 6px;
  line-height: 20px;
  margin: 0;
}

.storeboxesarea h5 {
  color: #000000;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding: 0px 6px 6px;
}

.swiper-button-prev {
  position: absolute;
  right: 20px;
  top: 0px;
  background: #c11c1c;
  width: 20px;
  height: 20px;
  z-index: 9;
}

.swiper-button-next {
  position: absolute;
  right: 0;
  top: 0px;
  background: #c11c1c;
  width: 20px;
  height: 20px;
  z-index: 9;
}

.main-breadcrumbs-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #adb3bc;
}
.main-breadcrumbs-div .breadcrumbs {
  padding: 20px 0;
}
.main-breadcrumbs-div .breadcrumbs ul {
  list-style: none;
}
.main-breadcrumbs-div .breadcrumbs ul li {
  display: inline-block;
  padding-right: 15px;
}
.main-breadcrumbs-div .breadcrumbs ul li + li {
  padding: 0 8px;
  margin: 0 10px;
  position: relative;
}
.main-breadcrumbs-div .breadcrumbs ul li + li::before {
  position: absolute;
  top: 9px;
  left: -12px;
  width: 6px;
  height: 2px;
  background: #adb3bc;
  content: "";
}
.main-breadcrumbs-div .breadcrumbs ul li a {
  color: #adb3bc;
  font-weight: 400;
  font-size: 13px;
  font-family: "RocknRoll One", sans-serif;
}
.main-breadcrumbs-div .breadcrumbs ul li.activelinkgray a {
  color: #000000;
}
.main-breadcrumbs-div .filter-reset-btn button {
  font-family: "SF Pro Text", sans-serif;
  background-color: transparent;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0076be;
  border: none;
}

.pagewrapper {
  background-color: #fff;
  border-radius: 20px;
}
.pagewrapper h3 {
  color: #000;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 58px;
  margin-bottom: 8px;
}
.pagewrapper.pt-50 {
  padding-top: 50px;
}
.pagewrapper.pb-50 {
  padding-bottom: 50px;
}
.pagewrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: "SF Pro Text", sans-serif;
  line-height: 28px;
  margin: 0;
  padding-bottom: 40px;
  color: #4a4a4a;
}

.whitebox-area {
  background: #fff;
  padding: 25px 20px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.whitebox-area img {
  width: 100px;
  height: 100px;
  border-radius: 150px;
}
.whitebox-area h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  margin: 0px;
  color: #000;
}

/**************************************8
            Splash Screen
*****************************************/
section.splashscreen {
  height: 100vh;
  position: relative;
  background: url(../images/splashBg.png) repeat-x;
  background-position: center bottom;
  background-size: cover;
  background-color: #2ce9fe;
}
section.splashscreen .container {
  height: 100vh;
}
section.splashscreen .middlearea {
  text-align: center;
  color: #fff;
}
section.splashscreen .splashLogo img {
  width: 250px;
}
section.splashscreen .splashLogo h2 {
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  margin-top: 30px;
}
section.splashscreen .splashLogo h2 span {
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;
  display: block;
}
section.splashscreen p {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 20px 0 0;
}
section.splashscreen .splashLogo a {
  background-color: #fff;
  padding: 10px;
  display: block;
  max-width: 432px;
  height: 55px;
  margin: auto;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  color: #0076be;
  font-family: "RocknRoll One", sans-serif;
  margin-top: 50px;
}

.LightWaves {
  animation: lightwaves 2s infinite;
  position: relative;
}

@keyframes lightwaves {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
    transform: scale(1.05);
  }
  50% {
    transform: translate(25px, 5px);
  }
  75% {
    transform: translate(12px, 10px);
    transform: scale(1.05);
  }
}
.mt-100 {
  margin-top: 110px;
}

/**************************************8
            Footer
*****************************************/
.spacearealink {
  width: 100%;
  height: 1px;
  background-color: #adb3bc;
  opacity: 0.25;
  margin: 50px 0;
}

.mgr-b30 {
  margin-bottom: 30px;
}

.branddetailbanner {
  background: #fff;
  padding: 36px 0;
  border-radius: 20px;
  text-align: center;
}
.branddetailbanner h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 43px;
  padding-top: 10px;
  margin: 0px;
  color: #000;
}
.branddetailbanner img {
  width: 125px;
  height: 125px;
  border-radius: 150px;
}

.trandingareabox .storeboxesarea {
  width: 330px;
  margin: 0 0 30px 30px;
}

.trandingareabox .storeboxesarea:nth-child(5n+1) {
  margin-left: 0px;
}

#js-prev1,
#js-next1,
#js-prev2,
#js-next2 {
  background: #ffffff;
  border: 1px solid #adb3bc;
  border-radius: 10px;
  padding: 0 !important;
  cursor: pointer;
  margin: 0 0 0 6px;
  width: 45px;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.blue-button {
  color: #fff;
  background: #0076be;
  font-family: "RocknRoll One", sans-serif;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 15px;
  border-color: #0076be;
  border: 0;
  float: right;
  margin: 18px 0 0 0;
  text-decoration: none;
}
.blue-button:focus {
  outline: none;
  box-shadow: none;
}
.blue-button:hover {
  color: #ffffff;
}

.button.submitbtn {
  width: 49%;
  line-height: 37px;
  font-size: 22px;
}

@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1920px;
    margin: auto;
  }
  .mainpadding {
    max-width: 1920px;
    margin: auto;
  }
}
@media only screen and (min-width: 1560px) {
  .trandingareabox .storeboxesarea {
    width: 260px;
  }
}
@media only screen and (max-width: 768px) {
  body header ul.profilelist {
    justify-content: center;
  }
  .logos {
    text-align: center;
    padding-bottom: 15px;
  }
  .header .profilelist {
    justify-content: center;
  }
  .homebanner .headingcontent {
    padding: 0 10px;
  }
  .homebanner .headingcontent h2 {
    font-size: 28px;
    line-height: inherit;
  }
  footer .footer-para {
    padding: 0;
  }
  .homebanner {
    height: 400px;
    background-size: 100% 100%;
  }
  .footer .footer-para {
    padding: 30px 0px;
  }
  .featurebox {
    margin-bottom: 25px;
  }
  .fboxarea {
    margin-bottom: 30px;
  }
  .advertisementarea {
    padding: 50px 0;
  }
  .advertisementarea .contentheading {
    padding: 0 30px;
    width: 100%;
  }
  .advertisementarea .contentheading h2 {
    font-size: 28px;
    line-height: inherit;
  }
  .advertisementarea .contentheading p {
    font-size: 16px;
    line-height: inherit;
  }
  .advertisementarea .contentheading a {
    margin-top: 20px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .homebanner .col-md-5.col-sm-12.col-12.my-auto {
    width: 100%;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .mainpadding {
    padding: 0 0;
  }
  .main-margin {
    margin: 32px 10px;
  }
  section.splashscreen {
    height: 100vh;
    position: relative;
    background: url(../images/splashBg.png) repeat-x;
    background-position: center bottom;
    background-size: cover;
    background-color: #2ce9fe;
  }
  section.splashscreen .container {
    height: 100vh;
  }
  section.splashscreen .middlearea {
    text-align: center;
    color: #fff;
  }
  section.splashscreen .splashLogo img {
    width: 250px;
  }
  section.splashscreen .splashLogo h2 {
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    margin-top: 30px;
  }
  section.splashscreen .splashLogo h2 span {
    font-weight: 400;
    font-size: 25px;
    line-height: 52px;
    display: block;
  }
  section.splashscreen p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    padding: 20px 0 0;
  }
  section.splashscreen .splashLogo a {
    background-color: #fff;
    padding: 10px;
    display: block;
    max-width: 432px;
    height: 55px;
    margin: auto;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #0076be;
    font-family: "RocknRoll One", sans-serif;
    margin-top: 50px;
  }
  .pagewrapper h3 {
    font-size: 30px;
    line-height: 58px;
    margin-bottom: 8px;
  }
  .pagewrapper.pt-50 {
    padding-top: 50px;
  }
  .pagewrapper.pb-50 {
    padding-bottom: 50px;
  }
  .pagewrapper p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    padding-bottom: 40px;
    color: #4a4a4a;
  }
  .main-breadcrumbs-div {
    display: block;
    align-items: center;
    justify-content: space-between;
  }
  .main-breadcrumbs-div .breadcrumbs {
    padding: 20px 0;
  }
  .main-breadcrumbs-div .breadcrumbs ul {
    list-style: none;
  }
  .main-breadcrumbs-div .breadcrumbs ul li {
    display: inline-block;
    padding-right: 15px;
  }
  .main-breadcrumbs-div .breadcrumbs ul li + li {
    padding: 0 8px;
    margin: 0 10px;
    position: relative;
  }
  .main-breadcrumbs-div .breadcrumbs ul li + li::before {
    position: absolute;
    top: 9px;
    left: -12px;
    width: 6px;
    height: 2px;
    content: "";
  }
  .main-breadcrumbs-div .breadcrumbs ul li a {
    font-weight: 400;
    font-size: 13px;
  }
  .main-breadcrumbs-div .breadcrumbs ul li.activelinkgray a {
    color: #000000;
  }
  .main-breadcrumbs-div .filter-reset-btn button {
    background-color: transparent;
    font-size: 16px;
    border: none;
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .branddetailbanner {
    padding: 36px 0;
    border-radius: 20px;
    text-align: center;
  }
  .branddetailbanner h2 {
    font-size: 25px;
    line-height: 43px;
    padding-top: 10px;
    margin: 0px;
  }
  .branddetailbanner img {
    width: 125px;
    height: 125px;
    border-radius: 150px;
  }
}
.main-productdetails-section .swiper {
  width: 100%;
  height: 100%;
}
.main-productdetails-section .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.main-productdetails-section .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-productdetails-section .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
.main-productdetails-section .swiper-slide {
  background-size: cover;
  background-position: center;
}
.main-productdetails-section .mySwiper2 {
  height: 80%;
  width: 100%;
}
.main-productdetails-section .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.main-productdetails-section .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
  cursor: pointer;
}
.main-productdetails-section .mySwiper .swiper-slide:not(:first-child) {
  margin-left: 10px;
}
.main-productdetails-section .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.main-productdetails-section .mySwiper .swiper-slide-thumb-active:before {
  background: rgba(0, 0, 0, 0.68);
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  content: "";
  border-radius: 10px;
}
.main-productdetails-section .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.main-productdetails-section .prodcut-detailcard-slider {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.main-productdetails-section .prodcut-detailcard-slider .mySwiper {
  margin-top: 20px;
}
.main-productdetails-section .product-details-section {
  border-radius: 20px;
  background-color: #ffffff;
  padding: 30px;
  height: 100%;
}
.main-productdetails-section .product-details-section .top-detail-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-productdetails-section .product-details-section .top-detail-section .product-detail-category {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
  margin: 0;
}
.main-productdetails-section .product-details-section .top-detail-section .product-detail-rating {
  margin: 0;
}
.main-productdetails-section .product-details-section .top-detail-section .product-detail-rating span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16.289px;
  color: #979797;
  padding-left: 3px;
}
.main-productdetails-section .product-details-section h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin-top: 10px;
}
.main-productdetails-section .product-details-section .second-detail-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
}
.main-productdetails-section .product-details-section .second-detail-section h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin: 0;
}
.main-productdetails-section .product-details-section .second-detail-section p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #48bf91;
  margin: 0;
}
.main-productdetails-section .product-details-section .bottom-border-line {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin-top: 38px;
}
.main-productdetails-section .product-details-section .product-detail-para {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  margin: 27px 0 0 0;
}
.main-productdetails-section .product-details-section .product-select-size {
  margin-top: 30px;
  position: relative;
}
.main-productdetails-section .product-details-section .product-select-size .input-group {
  border: none;
  position: relative;
}
.main-productdetails-section .product-details-section .product-select-size .input-group .input-group-text {
  font-family: "SF Pro Text", sans-serif;
  background-color: #f5f8ff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
  padding: 24px 30px 24px 11px;
}
.main-productdetails-section .product-details-section .product-select-size .input-group .input-group-text::after {
  content: "";
  border: 1px solid #adb3bc;
  opacity: 0.6;
  position: absolute;
  width: 2px;
  height: 35px;
  left: 118px;
}
.main-productdetails-section .product-details-section .product-select-size .form-select {
  background-image: url("../images/productdetails/droparrow.svg");
  cursor: pointer;
}
.main-productdetails-section .product-details-section .product-select-size select {
  font-family: "RocknRoll One", sans-serif;
  border: none;
  background-color: #f5f8ff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.main-productdetails-section .product-details-section .product-select-size select:focus {
  box-shadow: none;
}
.main-productdetails-section .product-details-section .product-select-color {
  margin-top: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #f5f8ff;
  padding: 24px 11px;
  border-radius: 10px;
  position: relative;
}
.main-productdetails-section .product-details-section .product-select-color p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
}
.main-productdetails-section .product-details-section .product-select-color p::after {
  content: "";
  border: 1px solid #adb3bc;
  opacity: 0.6;
  position: absolute;
  width: 2px;
  height: 35px;
  left: 118px;
  right: 0;
  top: 20px;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors {
  display: flex;
  align-items: center;
  margin-left: 26px;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors input {
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: 10px;
  border: none;
  padding: 5px;
  cursor: pointer;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors input:focus {
  border: none;
  box-shadow: none;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors input:checked {
  outline: 1px solid #0076BE;
  outline-offset: 2px;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors #darkgrey {
  background: #6D6F71;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors #black {
  background: #000000;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors #lightgrey {
  background: #E5E3E1;
}
.main-productdetails-section .product-details-section .product-select-color .radio-colors #pink {
  background: #ECBBA4;
}
.main-productdetails-section .product-details-section .quantity-addcard {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-productdetails-section .product-details-section .quantity-addcard .qunatity-div {
  display: flex;
  align-items: center;
  background: #f5f8ff;
  border-radius: 10px;
  max-width: 114.28px;
  margin-right: 30px;
  position: relative;
}
.main-productdetails-section .product-details-section .quantity-addcard .qunatity-div input {
  width: 114.28px;
  background: transparent;
  text-align: center;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-productdetails-section .product-details-section .quantity-addcard .qunatity-div input::placeholder {
  text-align: center;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-productdetails-section .product-details-section .quantity-addcard .qunatity-div .arrow-down-icon {
  left: 17px;
  position: absolute;
  cursor: pointer;
}
.main-productdetails-section .product-details-section .quantity-addcard .qunatity-div .arrow-up-icon {
  position: absolute;
  right: 17px;
  cursor: pointer;
}
.main-productdetails-section .product-details-section .quantity-addcard button {
  background: #0076be;
  border-radius: 10px;
  height: 55px;
  line-height: 55px;
  padding: 0;
}
.main-productdetails-section .product-details-section .quantity-addcard button span {
  padding-left: 9px;
}

@media (min-width: 768px) and (max-width: 1199px) {
  .product-detail-col-right {
    width: 50%;
  }
  .product-details-section {
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .product-details-section {
    margin-top: 20px;
  }
}
.side-menu {
  padding: 10px 20px 30px 20px;
  background: #ffffff;
  border-radius: 20px;
  height: 750px;
  position: relative;
}
.side-menu .inputfield {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 0;
}
.side-menu .inputfield a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
  display: flex;
  align-items: center;
}
.side-menu .inputfield a span.number {
  background: #48bf91;
  border-radius: 50%;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #ffffff;
  margin-left: 5px;
  width: 18px;
  height: 18px;
  text-align: center;
}
.side-menu .inputfield a.active {
  color: #0076be;
}
.side-menu .inputfield a.active::after {
  display: flex;
  content: " ";
  background-image: url("/src/assets/images/icons/arrow-icon.svg");
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  position: absolute;
  right: -10px;
  bottom: 8px;
  align-items: center;
  margin: 0;
  padding: 0;
}
.side-menu .inputfield a.active path {
  fill: #0076be;
}
.side-menu .inputfield a.noactive {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
}
.side-menu .inputfield a.noactive::after {
  display: none;
}
.side-menu .inputfield a.noactive path {
  fill: #adb3bc;
}
.side-menu .inputfield span {
  width: 40px;
}
.side-menu-offcanvas {
  padding: 0;
  background: #ffffff;
  border-radius: 20px;
  height: 100vh;
  position: relative;
}
.side-menu-offcanvas .inputfield {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 0;
}
.side-menu-offcanvas .inputfield a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
}
.side-menu-offcanvas .inputfield a span {
  background: #48bf91;
  border-radius: 50%;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 2px 5px;
}
.side-menu-offcanvas .inputfield a.active {
  color: #0076be;
}
.side-menu-offcanvas .inputfield a.active::after {
  display: flex;
  content: " ";
  background-image: url("/src/assets/images/icons/arrow-icon.svg");
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  position: absolute;
  right: -10px;
  bottom: 8px;
  align-items: center;
  margin: 0;
  padding: 0;
}
.side-menu-offcanvas .inputfield a.noactive {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
}
.side-menu-offcanvas .inputfield a.noactive::after {
  display: none;
}
.side-menu-offcanvas .inputfield svg {
  margin-right: 10px;
}

@media (min-width: 1025px) and (max-width: 5000px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: none;
  }
}
@media only screen and (max-width: 1600px) {
  .side-menu .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    margin: 0;
    padding-right: 2px;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .sidebar-class-col {
    width: 20%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: block !important;
  }
  section.side-menu {
    display: none;
  }
  .sidebar-class-col {
    width: 10%;
  }
}
@media screen and (max-width: 767px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: block !important;
  }
  section.side-menu {
    display: none;
  }
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    margin-bottom: 20px;
  }
}
.edit-profile-form {
  background: #ffffff;
  border-radius: 25px;
  padding: 22px 24px 22px 24px;
  height: 100%;
}
.edit-profile-form h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
  padding-top: 10px;
}
.edit-profile-form .border-bottomm {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin: 15px 0;
}
.edit-profile-form .profile-form {
  margin-top: 30px;
}
.edit-profile-form .profile-form .img-div {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
}
.edit-profile-form .profile-form .img-div img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  cursor: pointer;
}
.edit-profile-form .profile-form .img-div::after {
  content: url("../images/svg/camera.svg");
  position: absolute;
  width: 25px;
  height: 25px;
  background: #0076be;
  border-radius: 50%;
  border: 1px solid white;
  top: 77%;
  right: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.edit-profile-form .profile-form .img-div .file-upload {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.edit-profile-form .profile-form .input-fields .form-floating {
  margin-top: 15px;
}
.edit-profile-form .profile-form .input-fields input {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  border-radius: 0;
}
.edit-profile-form .profile-form .input-fields input:focus {
  border-bottom: 1px solid #0076be;
}
.edit-profile-form .profile-form .input-fields textarea {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  border-radius: 0;
  margin-top: 10px;
  height: 85px;
  resize: none;
  padding: 0;
}
.edit-profile-form .profile-form .input-fields textarea:focus {
  border-bottom: 1px solid #0076be;
}
.edit-profile-form .profile-form .input-fields label {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}
.edit-profile-form .profile-form .input-fields .form-floating > .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
}
.edit-profile-form .profile-form .input-fields .form-floating > .form-control:focus ~ label {
  opacity: 1;
}
.edit-profile-form .profile-form .input-fields .form-floating > .form-control:focus,
.edit-profile-form .profile-form .input-fields .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0;
}
.edit-profile-form .profile-form .input-fields .form-floating > .form-control:not(:placeholder-shown) ~ label,
.edit-profile-form .profile-form .input-fields .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  left: -13px;
}
.edit-profile-form .profile-form .input-fields .bio-label {
  font-family: "SF Pro Text", sans-serif;
  padding-left: 0;
  padding-top: 26px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}
.edit-profile-form .profile-form button {
  font-family: "RocknRoll One", sans-serif;
  background: #0076be;
  border-radius: 10px;
  padding: 15px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  transition: 0.2s ease-in;
  margin-top: 50px;
}
.edit-profile-form .profile-form button:hover {
  background: white;
  color: #0076be;
}

.main-brand-card .brand-card-section {
  background: #FFFFFF;
  border-radius: 25px;
  height: 100%;
  padding: 20px 24px 0 24px;
}
.main-brand-card .brand-card-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
  padding-top: 10px;
}
.main-brand-card .brand-card-section .border-bottomm {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin: 15px 0;
}
.main-brand-card .brand-card-section .brand-cards {
  padding: 10px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-brand-card .brand-card-section .brand-cards h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.main-brand-card .brand-card-section .brand-cards ul {
  padding: 0;
}
.main-brand-card .brand-card-section .brand-cards ul li {
  list-style: none;
  color: #4A4A4A;
}
.main-brand-card .brand-card-section .brand-cards ul li span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding: 2px 0;
  display: inline-block;
  padding-right: 42px;
  width: 100px;
}
.main-brand-card .brand-card-section .brand-cards a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ADB3BC;
  border: 1px solid #ADB3BC;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 15px;
}
.main-brand-card .brand-card-section .add-new-btn {
  padding: 20px 0;
}
.main-brand-card .brand-card-section .add-new-btn a {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #0076BE;
  cursor: pointer;
}
.main-brand-card .brand-card-section .add-new-btn a span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0076BE;
  cursor: pointer;
  padding-left: 5px;
  padding-bottom: 5;
}

@media (min-width: 768px) and (max-width: 991px) {
  .brand-section-col.col-xxl-4.col-xl-5.col-md-6.col-sm-12 {
    width: 70%;
  }
}
@media screen and (max-width: 575px) {
  .my-auto.brand-logo-div.col-sm-3.col-12 {
    text-align: center;
  }
  .main-brand-card .brand-card-section .brand-cards ul li span {
    display: block;
  }
  .my-auto.brand-redeem.col-sm-3.col-12 {
    text-align: center;
  }
  .brand-card-section > .brand-cards > .row > .brand-details-div > ul {
    display: flex;
    justify-content: space-around;
  }
  .main-brand-card .brand-card-section .brand-cards h2 {
    text-align: center;
  }
  .brand-details-div.col-sm-6.col-12 {
    margin: 20px 0;
  }
  .my-auto.brand-redeem.col-sm-3.col-12 {
    padding-bottom: 20px;
  }
}
.main-wallet-section .wallet-section {
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 20px;
}
.main-wallet-section .wallet-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 12px 0;
}
.main-wallet-section .wallet-section .available-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}
.main-wallet-section .wallet-section .available-credits p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.main-wallet-section .mycard-section {
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 30px 0;
}
.main-wallet-section .mycard-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 12px 0;
}
.main-wallet-section .mycard-section .wallet-card {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 14px 0;
}
.main-wallet-section .mycard-section .wallet-card h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.main-wallet-section .mycard-section .wallet-card p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
}
.main-wallet-section .mycard-section .add-new-btn {
  padding: 20px 0;
}
.main-wallet-section .mycard-section .add-new-btn a {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #0076BE;
  cursor: pointer;
}
.main-wallet-section .mycard-section .add-new-btn a span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0076BE;
  cursor: pointer;
  padding-left: 5px;
  padding-bottom: 5;
}
.main-wallet-section .transaction-history {
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 20px;
  height: 100%;
}
.main-wallet-section .transaction-history h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 12px 0;
}
.main-wallet-section .transaction-history .historycard {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 10px 0;
}
.main-wallet-section .transaction-history .historycard h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-wallet-section .transaction-history .historycard .date {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4A4A4A;
  margin: 5px 0;
}
.main-wallet-section .transaction-history .historycard .price {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .credit-card-history-main-div.col-xxl-9.col-xl-9.col-md-9 {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .wallet-credit-card-col.col-xxl-5.col-xl-5.col-lg-6.col-sm-6 {
    width: 50%;
  }
  .wallet-credit-card-history.col-xxl-5.col-xl-5.col-lg-6.col-md-9.col-sm-6 {
    width: 50%;
  }
  .credit-card-history-main-div.col-xxl-9.col-xl-9.col-md-9 {
    width: 90%;
  }
  .person-purchase {
    width: 100%;
    text-align: left !important;
  }
  .person-name.col-xl-6.col-lg-8.col-md-8 {
    width: 100%;
  }
  .main-wallet-section .mycard-section .wallet-card .col-md-6 {
    width: 100%;
    margin-top: 20px;
  }
  .my-auto.text-end.cards-svgs.col-md-3 {
    text-align: left !important;
  }
}
@media screen and (max-width: 767px) {
  .my-auto.text-end.cards-svgs.col-md-3 {
    text-align: left !important;
  }
  .main-wallet-section .mycard-section .wallet-card h2 {
    margin-top: 20px;
  }
  .person-purchase {
    width: 100%;
    text-align: left !important;
  }
}
@media screen and (max-width: 575px) {
  .wallet-credit-card-col.col-xxl-5.col-xl-5.col-lg-6.col-sm-6 {
    margin-bottom: 20px;
  }
}
.main-review-section .review-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  height: 100%;
}
.main-review-section .review-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 12px;
}
.main-review-section .review-section .review-cards {
  padding: 10px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-review-section .review-section .review-cards .review-img-section h5 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.main-review-section .review-section .review-cards .review-img-section p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.main-review-section .review-section .review-cards .review-img-section small {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
}

@media (min-width: 320px) and (max-width: 1199px) {
  .main-review-section .review-section .review-cards .review-img-section h5 {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin: 0;
    padding-top: 10px;
  }
  .main-review-section .review-section .review-cards .review-img-section p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin: 0;
    padding-top: 5px;
  }
  .card-body.py-0.px-3 {
    padding-left: 0 !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .order-review-card-width {
    width: 90%;
  }
}
.switch {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 24px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
  margin-left: auto;
}

.switch::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 18px;
  height: 18px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

input:checked + .switch {
  background: #34C759;
}

input:checked + .switch::before {
  left: 20px;
  background: #fff;
}

input:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

.settings {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
  height: 100%;
}
.settings h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 12px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.settings .setting-tab .inputfield {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 0;
}
.settings .setting-tab .inputfield a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
}
.settings .setting-tab .inputfield a.active {
  color: #0076BE;
}
.settings .setting-tab .inputfield a.active path {
  fill: #0076BE;
}
.settings .setting-tab .inputfield a.noactive {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
}
.settings .setting-tab .inputfield a.noactive::after {
  display: none;
}
.settings .setting-tab .inputfield a.noactive path {
  fill: #adb3bc;
}
.settings .setting-tab .inputfield a span {
  width: 40px;
}
.change-password {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  height: 100%;
}
.change-password h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding-bottom: 24px;
  padding-left: 30px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.change-password p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #4a4a4a;
  padding: 30px 30px 0 30px;
}
.change-password .form-section {
  padding: 30px 53px 0 30px;
  position: relative;
}
.change-password .form-section input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  padding-left: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.change-password .form-section input:focus {
  box-shadow: none;
  border-bottom: 1px solid #2a7fc5;
}
.change-password .form-section label {
  padding-left: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}
.change-password .form-section button {
  background: #0076be;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  transition: 0.2s ease-in;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding: 10px 0;
  margin: 50px 0;
  border: 1px solid #0076be;
}
.change-password .form-section button:focus {
  box-shadow: none;
}
.change-password .form-section button:hover {
  background-color: #ffffff;
  color: #0076be;
  border: 1px solid #0076be;
}
.change-password .form-section .toggle-password {
  position: absolute;
  right: 0;
  top: 33%;
  background: white;
  bottom: 0;
  height: 25px;
  width: 30px;
  cursor: pointer;
  text-align: end;
}
.change-password .form-section .form-floating > .form-control:not(:placeholder-shown) ~ label,
.change-password .form-section .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(-3px);
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .change-password {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .change-password h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding-bottom: 24px;
    padding-left: 30px;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .change-password p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
    padding: 30px 30px 0 30px;
  }
  .change-password .form-section {
    padding: 30px 53px 0 30px;
    position: relative;
  }
  .change-password .form-section input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    padding-left: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .change-password .form-section input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .change-password .form-section label {
    padding-left: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .change-password .form-section button {
    background: #0076be;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    transition: 0.2s ease-in;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 10px 0;
    margin: 50px 0;
    border: 1px solid #0076be;
  }
  .change-password .form-section button:focus {
    box-shadow: none;
  }
  .change-password .form-section button:hover {
    background-color: #ffffff;
    color: #0076be;
    border: 1px solid #0076be;
  }
  .change-password .form-section .toggle-password {
    position: absolute;
    right: 0;
    top: 35%;
    height: 25px;
    bottom: 0;
    cursor: pointer;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .change-password {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .change-password h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding-bottom: 24px;
    padding-left: 30px;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .change-password p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
    padding: 30px 30px 0 30px;
  }
  .change-password .form-section {
    padding: 30px 53px 0 30px;
    position: relative;
  }
  .change-password .form-section input {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    padding-left: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .change-password .form-section input:focus {
    box-shadow: none;
    border-bottom: 1px solid #2a7fc5;
  }
  .change-password .form-section label {
    padding-left: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .change-password .form-section button {
    background: #0076be;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    transition: 0.2s ease-in;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 10px 0;
    margin: 50px 0;
    border: 1px solid #0076be;
  }
  .change-password .form-section button:focus {
    box-shadow: none;
  }
  .change-password .form-section button:hover {
    background-color: #ffffff;
    color: #0076be;
    border: 1px solid #0076be;
  }
  .change-password .form-section .toggle-password {
    position: absolute;
    right: 0;
    top: 20%;
    bottom: 0;
    height: 35px;
    cursor: pointer;
  }
}
@media (min-width: 1025px) and (max-width: 1198px) {
  .main-changepassword-section .changepassword-col {
    width: 45%;
  }
  .main-changepassword-section .setting-col {
    width: 30% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main-changepassword-section .changepassword-col {
    width: 50%;
  }
  .main-changepassword-section .setting-col {
    width: 40% !important;
  }
}
.terms-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  height: 750px;
}
.terms-section::-webkit-scrollbar {
  display: none;
}
.terms-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 12px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.terms-section .terms-content {
  padding: 30px 0 0 0;
}
.terms-section .terms-content p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-terms-section .terms-section .terms-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .main-terms-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-terms-section .terms-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .main-terms-section .terms-section .terms-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .main-terms-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-terms-section .terms-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .main-terms-section .terms-section .terms-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4a4a4a;
  }
  .main-terms-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media (min-width: 1199px) and (max-width: 1500px) {
  .main-terms-section .terms-col {
    width: 45%;
  }
  .main-terms-section .setting-col {
    width: 30% !important;
  }
}
@media (min-width: 1025px) and (max-width: 1198px) {
  .main-terms-section .terms-col {
    width: 40%;
  }
  .main-terms-section .setting-col {
    width: 30% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main-terms-section .terms-col {
    width: 45%;
  }
  .main-terms-section .setting-col {
    width: 44% !important;
  }
}
.privacy-section {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px;
  overflow: scroll;
  height: 750px;
}
.privacy-section::-webkit-scrollbar {
  display: none;
}
.privacy-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 12px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.privacy-section .privacy-content {
  padding: 30px 0 0 0;
}
.privacy-section .privacy-content p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4A4A4A;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-privacy-section .privacy-section .privacy-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .main-privacy-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-privacy-section .privacy-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .main-privacy-section .privacy-section .privacy-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .main-privacy-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-privacy-section .privacy-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    margin-top: 20px;
  }
  .main-privacy-section .privacy-section .privacy-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4a4a4a;
  }
  .main-privacy-section .settings .setting-tab .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-left: 25px;
  }
}
@media (min-width: 1199px) and (max-width: 1500px) {
  .main-privacy-section .privacy-col {
    width: 45%;
  }
  .main-privacy-section .setting-col {
    width: 30% !important;
  }
}
@media (min-width: 1025px) and (max-width: 1198px) {
  .main-privacy-section .privacy-col {
    width: 40%;
  }
  .main-privacy-section .setting-col {
    width: 30% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main-privacy-section .privacy-col {
    width: 45%;
  }
  .main-privacy-section .setting-col {
    width: 44% !important;
  }
}
.main-advertisement-section .card {
  background: #ffffff;
  border-radius: 13.6003px;
}
.main-advertisement-section .card img {
  padding: 10px;
}
.main-advertisement-section .card .exipry-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-advertisement-section .card .exipry-section .expiry {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #979797;
}
.main-advertisement-section .card .exipry-section .valid {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #48bf91;
}
.main-advertisement-section .card .exipry-section .expired {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #d44357;
}
.main-advertisement-section .card .card-title,
.main-advertisement-section .card h5 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19.6233px;
  color: #000000;
}
.main-advertisement-section .card p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-advertisement-section .card .small-text {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #979797;
  white-space: nowrap;
  width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-advertisement-section {
    margin-top: 20px;
  }
}
.logout-popup {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 586px;
  height: 350px;
  margin: auto auto;
}
.logout-popup .bottom-border {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.logout-popup h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding: 25px 0;
}
.logout-popup p {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  margin-top: 29px;
}
.logout-popup .yes-btn {
  font-family: "RocknRoll One", sans-serif;
  background: #0076be;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding: 15px 94px;
}
.logout-popup .no-btn {
  font-family: "RocknRoll One", sans-serif;
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #adb3bc;
  padding: 15px 94px;
}
.logout-popup .btn-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .logout-popup {
    width: 100%;
    height: 350px;
  }
  .logout-popup .yes-btn {
    font-size: 18px;
    padding: 10px 50px;
  }
  .logout-popup .no-btn {
    font-size: 18px;
    padding: 10px 50px;
  }
}
.notificationpanel {
  position: fixed;
  top: 70px;
  right: 100px;
  display: none;
  z-index: 9;
}
.notificationpanel.active {
  display: block;
}
.notificationpanel.noactive {
  display: none;
}

.unread-bg {
  background: #f5f8ff;
  border-radius: 10px;
}

.top-border {
  border-top: 1px solid rgba(173, 179, 188, 0.5);
  background: #ffffff;
  border-radius: 0;
}

.notification-popup {
  width: 440px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 15px 45px 15px;
}
.notification-popup .notification-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  margin-top: 21px;
}
.notification-popup .notification-heading-section h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.notification-popup .notification-heading-section h1 span {
  font-family: "RocknRoll One", sans-serif;
  color: #48bf91;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.notification-popup .bottom-border {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.notification-popup .main-notification {
  height: 590px;
  overflow-y: auto;
}
.notification-popup .main-notification::-webkit-scrollbar {
  display: none;
}
.notification-popup .main-notification .notification-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  margin-bottom: 10px;
}
.notification-popup .main-notification .notification-card .store-heading {
  margin-right: 20px;
}
.notification-popup .main-notification .notification-card h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin: 0;
}
.notification-popup .main-notification .notification-card p {
  /* margin-bottom: auto; */
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #818181;
  /* margin-top: 5px; */
  margin-bottom: auto;
}
.notification-popup .main-notification .notification-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .notificationpanel {
    position: fixed;
    top: 70px;
    right: 100px;
    display: none;
    z-index: 9;
  }
  .notificationpanel.active {
    display: block;
  }
  .notificationpanel.noactive {
    display: none;
  }
  .unread-bg {
    background: #f5f8ff;
    border-radius: 10px;
  }
  .top-border {
    border-top: 1px solid rgba(173, 179, 188, 0.5);
    background: #ffffff;
    border-radius: 0;
  }
  .notification-popup {
    width: 440px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;
  }
  .notification-popup .notification-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .notification-popup .notification-heading-section h1 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .notification-popup .notification-heading-section h1 span {
    font-family: "RocknRoll One", sans-serif;
    color: #48bf91;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .notification-popup .bottom-border {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .notification-popup .main-notification .notification-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    margin-bottom: 10px;
  }
  .notification-popup .main-notification .notification-card .store-heading {
    margin-right: 20px;
  }
  .notification-popup .main-notification .notification-card h2 {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin: 0;
  }
  .notification-popup .main-notification .notification-card p {
    margin-bottom: auto;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #818181;
  }
  .notification-popup .main-notification .notification-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .notificationpanel {
    position: fixed;
    top: 70px;
    right: 0;
    left: 0;
    display: none;
    z-index: 9;
    width: 100%;
  }
  .notificationpanel.active {
    display: block;
  }
  .notificationpanel.noactive {
    display: none;
  }
  .unread-bg {
    background: #f5f8ff;
    border-radius: 10px;
  }
  .top-border {
    border-top: 1px solid rgba(173, 179, 188, 0.5);
    background: #ffffff;
    border-radius: 0;
  }
  .notification-popup {
    width: 100%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;
  }
  .notification-popup .notification-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .notification-popup .notification-heading-section h1 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .notification-popup .notification-heading-section h1 span {
    font-family: "RocknRoll One", sans-serif;
    color: #48bf91;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .notification-popup .bottom-border {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .notification-popup .main-notification {
    height: 420px;
  }
  .notification-popup .main-notification .notification-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    margin-bottom: 10px;
  }
  .notification-popup .main-notification .notification-card .store-heading {
    margin-right: 20px;
    padding-left: 10px;
  }
  .notification-popup .main-notification .notification-card h2 {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin: 0;
  }
  .notification-popup .main-notification .notification-card p {
    margin-bottom: auto;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #818181;
  }
  .notification-popup .main-notification .notification-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
.profilepanel {
  position: fixed;
  top: 70px;
  right: 100px;
  display: none;
  z-index: 9;
}
.profilepanel.active {
  display: block;
}
.profilepanel.noactive {
  display: none;
}

.myprofile-popup {
  width: 399px;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 25px 15px 45px 15px;
}
.myprofile-popup::-webkit-scrollbar {
  width: 0;
}
.myprofile-popup .myprofile-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 10px;
}
.myprofile-popup .myprofile-heading-section h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.myprofile-popup .myprofile-heading-section h1 span {
  font-family: "RocknRoll One", sans-serif;
  color: #48bf91;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.myprofile-popup .bottom-border {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin-top: 20px;
  margin-bottom: 0;
}
.myprofile-popup .profilesection {
  display: flex;
  align-items: center;
  margin: 27px 0 0 0;
}
.myprofile-popup .profilesection .infodiv {
  padding-left: 31px;
}
.myprofile-popup .profilesection .infodiv h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.myprofile-popup .profilesection .infodiv p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}
.myprofile-popup .profilesection img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.myprofile-popup .bio {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4a4a4a;
  margin-top: 12px;
}
.myprofile-popup .myprofile-nav {
  height: calc(100vh - 425px);
  overflow: auto;
}
.myprofile-popup .myprofile-nav::-webkit-scrollbar {
  display: none;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu {
  background: #ffffff;
  border-radius: 20px;
  position: relative;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 0;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  padding-right: 2px;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a.active {
  color: #0076be;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a.active::after {
  display: flex;
  content: " ";
  background-image: url("/src/assets/images/icons/arrow-icon.svg");
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  position: absolute;
  right: -10px;
  bottom: 8px;
  align-items: center;
  margin: 0;
  padding: 0;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield span {
  background: #48bf91;
  border-radius: 50%;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  padding: 2px 5px;
  margin-left: auto;
}
.myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield svg {
  margin-right: 25px;
  margin-left: 25px;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .profilepanel {
    position: fixed;
    top: 70px;
    right: 0;
    left: 0;
    display: none;
    z-index: 9;
    width: 100%;
  }
  .profilepanel.active {
    display: block;
  }
  .profilepanel.noactive {
    display: none;
  }
  .myprofile-popup {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 25px 15px 45px 15px;
    overflow-y: scroll;
  }
  .myprofile-popup .myprofile-heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 0;
  }
  .myprofile-popup .myprofile-heading-section h1 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .myprofile-popup .myprofile-heading-section h1 span {
    font-family: "RocknRoll One", sans-serif;
    color: #48bf91;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
  .myprofile-popup .bottom-border {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    margin-top: 20px;
  }
  .myprofile-popup .profilesection {
    display: flex;
    align-items: center;
  }
  .myprofile-popup .profilesection .infodiv {
    padding-left: 31px;
  }
  .myprofile-popup .profilesection .infodiv h1 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .myprofile-popup .profilesection .infodiv p {
    margin: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a;
  }
  .myprofile-popup .profilesection img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .myprofile-popup .bio {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
    margin-top: 12px;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu {
    background: #ffffff;
    border-radius: 20px;
    position: relative;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin: 0;
    padding-right: 2px;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a.active {
    color: #0076be;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield a.active::after {
    display: flex;
    content: " ";
    background-image: url("/src/assets/images/icons/arrow-icon.svg");
    background-repeat: no-repeat;
    height: 28px;
    width: 28px;
    position: absolute;
    right: -10px;
    bottom: 8px;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield span {
    background: #48bf91;
    border-radius: 50%;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #ffffff;
    padding: 2px 5px;
    margin-left: auto;
  }
  .myprofile-popup .myprofile-nav .myprofile-side-menu .inputfield svg {
    margin-right: 25px;
  }
}
.delete-popup {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 586px;
  height: 350px;
  margin: auto auto;
}
.delete-popup .bottom-border {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.delete-popup h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding: 25px 0;
}
.delete-popup p {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  margin-top: 29px;
}
.delete-popup .yes-btn {
  font-family: "RocknRoll One", sans-serif;
  background: #0076be;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  padding: 15px 94px;
}
.delete-popup .no-btn {
  font-family: "RocknRoll One", sans-serif;
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ADB3BC;
  padding: 15px 94px;
}
.delete-popup .btn-div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .delete-popup {
    width: 100%;
    height: 350px;
  }
  .delete-popup .yes-btn {
    font-size: 18px;
    padding: 10px 50px;
  }
  .delete-popup .no-btn {
    font-size: 18px;
    padding: 10px 50px;
  }
}
.confirmed {
  color: #48bf91;
}

.pending {
  color: #ff8847;
}

.right-border {
  border-right: 1px solid rgba(173, 179, 188, 0.5) !important;
  border-radius: 0 !important;
}

.main-orders-section .order-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 22px 24px 22px 24px;
  height: 100%;
}
.main-orders-section .order-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
  padding-top: 10px;
}
.main-orders-section .order-section .border-bottomm {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin: 15px 0 0 0;
}
.main-orders-section .order-section .tabs-section ul {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-orders-section .order-section .tabs-section ul .nav-link {
  background-color: transparent;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #adb3bc;
  margin: 16px 0;
}
.main-orders-section .order-section .tabs-section ul .nav-link.active {
  color: #0076be;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding-bottom: 20px;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .img-title-div {
  display: flex;
  align-items: center;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .img-title-div .title-date {
  padding-left: 20px;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .img-title-div .title-date p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #979797;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .img-title-div .title-date h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-top: 4px;
  margin-bottom: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .order-quantity p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .order-price p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .status p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .present-orders .order-card .status a {
  display: block;
  text-align: right;
  margin-top: 9px;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding-bottom: 20px;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .img-title-div {
  display: flex;
  align-items: center;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .img-title-div .title-date {
  padding-left: 20px;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .img-title-div .title-date p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #979797;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .img-title-div .title-date h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .order-quantity p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .order-price p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .status p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}
.main-orders-section .order-section .tabs-section .tab-content .past-orders .order-card .status a {
  display: block;
  text-align: right;
  margin-top: 9px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .myorder-details-col {
    width: 83.33%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .myorder-details-col.col-xxl-6.col-xl-8.col-md-8.col-sm-12 {
    width: 100%;
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .myorder-tab-content {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    will-change: unset;
  }
  .myorder-pills-home {
    min-width: 700px;
  }
  div#pills-profile {
    min-width: 500px;
  }
  ::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
}
.main-store-chat {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 36px;
  padding-right: 36px;
  height: 100%;
  padding: 22px 24px 22px 24px;
  position: relative;
}
.main-store-chat .heading-div {
  position: relative;
}
.main-store-chat .heading-div h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.main-store-chat .heading-div svg {
  position: absolute;
  right: 0;
  top: 0;
}
.main-store-chat .day-div p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #adb3bc;
}
.main-store-chat .main-chat-container {
  height: 100%;
  max-height: 540px;
  overflow-y: auto;
}
.main-store-chat .main-chat-container .sender-chat-div {
  margin-top: 19px;
}
.main-store-chat .main-chat-container .sender-chat-div .img-text {
  display: flex;
  align-items: center;
}
.main-store-chat .main-chat-container .sender-chat-div .img-text p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  background: #f4f9fb;
  border-radius: 25px 25px 25px 0px;
  padding: 22px 74px 22px 22px;
  margin-left: 17px;
  margin-bottom: 0;
}
.main-store-chat .main-chat-container .sender-chat-div .time {
  margin-bottom: 0;
  text-align: end;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #979797;
  margin-top: 10px;
}
.main-store-chat .main-chat-container .receiver-chat-div {
  margin-top: 31px;
}
.main-store-chat .main-chat-container .receiver-chat-div .img-text {
  display: flex;
  align-items: center;
}
.main-store-chat .main-chat-container .receiver-chat-div .img-text p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  background: #f4f9fb;
  border-radius: 25px 25px 0px 25px;
  padding: 22px 74px 22px 22px;
  margin-right: 17px;
  margin-bottom: 0;
}
.main-store-chat .main-chat-container .receiver-chat-div .time {
  margin-bottom: 0;
  text-align: start;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #979797;
  margin-top: 10px;
}
.main-store-chat .message-container {
  position: relative;
}
.main-store-chat .message-wrapper {
  background-color: #f4f9fb;
  border-radius: 10px;
}
.main-store-chat .message-wrapper .profile-wrapper {
  left: 1rem;
  top: 10px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
}
.main-store-chat .message-wrapper .profile-wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.main-store-chat .message-wrapper .btn-send {
  padding: 0;
  background: transparent;
}
.main-store-chat .message-wrapper .btn-send:hover, .main-store-chat .message-wrapper .btn-send:focus {
  background: transparent;
}
.main-store-chat .message-wrapper input,
.main-store-chat .message-wrapper .form-control {
  border: none;
  background: transparent;
  resize: none;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  padding: 15px 10px;
}
.main-store-chat .message-wrapper input:hover, .main-store-chat .message-wrapper input:focus,
.main-store-chat .message-wrapper .form-control:hover,
.main-store-chat .message-wrapper .form-control:focus {
  border: none;
  box-shadow: none;
  outline: none;
}
.main-store-chat .message-wrapper input::placeholder,
.main-store-chat .message-wrapper .form-control::placeholder {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.main-store-chat .message-wrapper input::-webkit-scrollbar,
.main-store-chat .message-wrapper .form-control::-webkit-scrollbar {
  display: none;
}
.main-store-chat .message-wrapper .input-group {
  border: none;
}
.main-store-chat .message-wrapper .input-group:focus {
  border: none;
}
.main-store-chat .message-wrapper .input-group-text {
  border: none;
  background: transparent;
  cursor: pointer;
}
.main-store-chat .message-wrapper .input-group-text svg {
  cursor: pointer;
}
.main-store-chat .main-img-preview-div {
  position: absolute;
  bottom: 81px;
  width: 88%;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div .top-div {
  display: flex;
  align-items: center;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div .top-div .img-chat-div {
  width: 60px;
  height: 40px;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div .top-div .img-chat-div img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div .top-div .img-name-div p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  width: 100px;
  margin-left: 5px;
}
.main-store-chat .main-img-preview-div .sub-img-preview-div .close-btn-div button {
  background-color: transparent;
  border: none;
}

.pl-0 {
  padding-left: 0 !important;
}

.main-cart-table-section .cart-table {
  background: #ffffff !important;
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
  height: 557px;
}
.main-cart-table-section .cart-table table thead {
  background: #0076be;
  border-radius: 20px;
}
.main-cart-table-section .cart-table table thead tr th {
  border-style: none;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  padding-top: 17px;
  padding-bottom: 17px;
}
.main-cart-table-section .cart-table table thead tr th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.main-cart-table-section .cart-table table thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.main-cart-table-section .cart-table table thead tr th:not(:first-child) {
  text-align: center;
}
.main-cart-table-section .cart-table table tbody tr td {
  vertical-align: middle;
  text-align: center;
}
.main-cart-table-section .cart-table .order-img-title {
  display: flex;
  align-items: center;
}
.main-cart-table-section .cart-table .order-img-title h2 {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  padding-left: 55px;
  margin: 0;
}
.main-cart-table-section .cart-table .quantity p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
}
.main-cart-table-section .cart-table .price p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.main-cart-table-section .cart-table .total p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.main-cart-table-section .cart-table .edit-delete-btns a:first-child {
  margin-right: 23px;
}
.main-cart-table-section .amount-details {
  background: #ffffff !important;
  border-radius: 20px;
  padding: 20px 20px 20px 20px;
}
.main-cart-table-section .amount-details h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding-bottom: 24px;
}
.main-cart-table-section .amount-details .checkout-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-top: 29px;
}
.main-cart-table-section .amount-details .checkout-total p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  background: #f5f8ff;
  border-radius: 10px;
  /* padding: 19px 8px; */
  /* width: 45%; */
  width: 248px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  margin-right: 40px;
}
.main-cart-table-section .amount-details .checkout-total p span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
}
.main-cart-table-section .amount-details .checkout-total a {
  background: #0076be;
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  /* padding: 19px 8px; */
  /* width: 45%; */
  width: 248px;
  height: 55px;
  line-height: 55px;
  text-align: center;
}
.main-cart-table-section .amount-details .checkout-total a span {
  margin-right: 10px;
}

@media screen and (max-width: 1300px) {
  .cart-table > .table {
    min-width: 700px;
  }
  .cart-table {
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    will-change: unset;
  }
  ::-webkit-scrollbar {
    height: 4px; /* height of horizontal scrollbar ← You're missing this */
    width: 4px; /* width of vertical scrollbar */
    border: 1px solid #d5d5d5;
  }
  .main-cart-table-section .amount-details .checkout-total p {
    font-size: 18px;
  }
  .main-cart-table-section .amount-details .checkout-total a {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .main-cart-table-section .amount-details .checkout-total {
    display: block;
  }
  .main-cart-table-section .amount-details .checkout-total p {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .main-cart-table-section .amount-details .checkout-total a {
    width: 100%;
    text-align: center;
    display: block;
  }
  .cart-table-div.col-xxl-8.col-md-7 {
    width: 100%;
    margin-bottom: 20px;
  }
}
.confirmed-order .confirmed-order-container {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 54px;
  max-width: 586px;
  margin: 0 auto;
}
.confirmed-order .confirmed-order-container .heading-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.confirmed-order .confirmed-order-container .heading-div h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding: 25px 0;
  margin: 0 auto;
}
.confirmed-order .confirmed-order-container .heading-div svg {
  cursor: pointer;
}
.confirmed-order .confirmed-order-container .order-svg {
  margin: 20px 0;
}
.confirmed-order .confirmed-order-container .order-svg svg {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.confirmed-order .confirmed-order-container .middle-text p {
  margin: 0;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.confirmed-order .confirmed-order-container .small-para {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 25px;
}
.confirmed-order .confirmed-order-container a {
  background: #0076be;
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  /* margin-top: 25px; */
  transition: 0.2s ease-in;
  /* padding: 14px 0; */
  display: block;
  max-width: 432px;
  height: 55px;
  line-height: 55px;
  margin: 0 auto;
}
.confirmed-order .confirmed-order-container a:hover {
  background: #ffffff;
  color: #0076be;
  border: 1px solid #0076be;
}

.main-payment-section .selectbrand-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 26px 30px;
}
.main-payment-section .selectbrand-section .input-group {
  padding: 14px 0;
}
.main-payment-section .selectbrand-section .input-group select {
  border: none;
  border-left: 1px solid rgba(173, 179, 188, 0.5);
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-overflow: ellipsis !important;
  cursor: pointer;
}
.main-payment-section .selectbrand-section .input-group select:focus {
  box-shadow: none;
}
.main-payment-section .selectbrand-section .input-group span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #4a4a4a;
}
.main-payment-section .shipping-address-section {
  background: #ffffff;
  border-radius: 20px;
  margin-top: 30px;
  padding-left: 24px;
  padding-right: 24px;
}
.main-payment-section .shipping-address-section h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 25px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .shipping-address-section p {
  padding: 0;
  margin: 0;
}
.main-payment-section .shipping-address-section .address {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding: 14px 0;
}
.main-payment-section .shipping-address-section .address p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 32px;
}
.main-payment-section .shipping-address-section .add-new-address {
  padding: 28px 0;
  display: flex;
  align-items: center;
}
.main-payment-section .shipping-address-section .add-new-address p {
  margin-bottom: 0;
  margin-top: 1px;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0076BE;
}
.main-payment-section .shipping-address-section .add-new-address svg {
  margin-right: 5px;
}
.main-payment-section .order-details {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
}
.main-payment-section .order-details h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 25px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .order-details .inner-order-details .card-details-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-payment-section .order-details .inner-order-details .card-details-div p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.main-payment-section .order-details .inner-order-details .card-details-div .price {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-payment-section .select-bank-card {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 30px;
}
.main-payment-section .select-bank-card h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 25px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .select-bank-card .strip-checkmark-div {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .select-bank-card .strip-checkmark-div .form-check {
  margin: 40px 0;
  width: 115px;
}
.main-payment-section .select-bank-card .strip-checkmark-div input {
  width: 25px;
  height: 30px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.main-payment-section .select-bank-card .strip-checkmark-div input:focus {
  box-shadow: none;
}
.main-payment-section .select-bank-card .strip-checkmark-div label {
  cursor: pointer;
  margin-top: 3px;
}
.main-payment-section .select-bank-card .add-new-address {
  padding: 28px 0;
  display: flex;
  align-items: center;
}
.main-payment-section .select-bank-card .add-new-address p {
  margin-bottom: 0;
  margin-top: 1px;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0076BE;
}
.main-payment-section .select-bank-card .add-new-address svg {
  margin-right: 5px;
}
.main-payment-section .payment-summary {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
}
.main-payment-section .payment-summary h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 25px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .payment-summary .inner-order-details .card-details-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-payment-section .payment-summary .inner-order-details .card-details-div p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.main-payment-section .payment-summary .inner-order-details .card-details-div .price {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.main-payment-section .place-order {
  background: #ffffff;
  border-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 30px;
}
.main-payment-section .place-order h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  padding: 25px 0;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-payment-section .place-order .total-price-div {
  margin-top: 34px;
}
.main-payment-section .place-order .total-price-div p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
}
.main-payment-section .place-order .total-price-div p span {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #979797;
}
.main-payment-section .place-order .total-price-div input {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.main-payment-section .place-order .total-price-div label {
  cursor: pointer;
}
.main-payment-section .place-order .add-new-address {
  padding: 28px 0;
  display: flex;
  align-items: center;
}
.main-payment-section .place-order .add-new-address p {
  margin-bottom: 0;
}
.main-payment-section .place-order .add-new-address svg {
  margin-right: 5px;
}
.main-payment-section .place-order .add-new-address button {
  background: #0076be;
  border-radius: 10px;
}
.main-payment-section .place-order .add-new-address .btn-width {
  width: 248px;
  height: 55px;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .main-payment-section .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 30px;
  }
  .main-payment-section .selectbrand-section .input-group {
    padding: 14px 0;
  }
  .main-payment-section .selectbrand-section .input-group select {
    border: none;
    border-left: 1px solid rgba(173, 179, 188, 0.5);
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .selectbrand-section .input-group select:focus {
    box-shadow: none;
  }
  .main-payment-section .selectbrand-section .input-group span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4a4a4a;
  }
  .main-payment-section .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .shipping-address-section h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .shipping-address-section p {
    padding: 0;
    margin: 0;
  }
  .main-payment-section .shipping-address-section .address {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    padding: 14px 0;
  }
  .main-payment-section .shipping-address-section .address p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 32px;
  }
  .main-payment-section .shipping-address-section .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .shipping-address-section .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .shipping-address-section .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .order-details h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .order-details .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .select-bank-card h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div .form-check {
    margin: 40px 0;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div label {
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .select-bank-card .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .select-bank-card .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .payment-summary h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .place-order h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .place-order .total-price-div {
    margin-top: 34px;
  }
  .main-payment-section .place-order .total-price-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }
  .main-payment-section .place-order .total-price-div p span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #979797;
  }
  .main-payment-section .place-order .total-price-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .place-order .total-price-div label {
    cursor: pointer;
  }
  .main-payment-section .place-order .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .place-order .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .place-order .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .place-order .add-new-address button {
    background: #0076be;
    border-radius: 10px;
  }
  .main-payment-section .place-order .add-new-address .btn-width {
    width: 248px;
    height: 55px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-payment-section .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 30px;
  }
  .main-payment-section .selectbrand-section .input-group {
    padding: 14px 0;
  }
  .main-payment-section .selectbrand-section .input-group select {
    border: none;
    border-left: 1px solid rgba(173, 179, 188, 0.5);
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .selectbrand-section .input-group select:focus {
    box-shadow: none;
  }
  .main-payment-section .selectbrand-section .input-group span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4a4a4a;
  }
  .main-payment-section .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .shipping-address-section h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .shipping-address-section p {
    padding: 0;
    margin: 0;
  }
  .main-payment-section .shipping-address-section .address {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    padding: 14px 0;
  }
  .main-payment-section .shipping-address-section .address p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 32px;
  }
  .main-payment-section .shipping-address-section .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .shipping-address-section .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .shipping-address-section .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .order-details h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .order-details .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .select-bank-card h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div .form-check {
    margin: 40px 0;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div label {
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .select-bank-card .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .select-bank-card .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .payment-summary h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .place-order h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .place-order .total-price-div {
    margin-top: 34px;
  }
  .main-payment-section .place-order .total-price-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }
  .main-payment-section .place-order .total-price-div p span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #979797;
  }
  .main-payment-section .place-order .total-price-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .place-order .total-price-div label {
    cursor: pointer;
  }
  .main-payment-section .place-order .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .place-order .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .place-order .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .place-order .add-new-address button {
    background: #0076be;
    border-radius: 10px;
  }
  .main-payment-section .place-order .add-new-address .btn-width {
    width: 248px;
    height: 55px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .main-payment-section .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 30px;
  }
  .main-payment-section .selectbrand-section .input-group {
    padding: 14px 0;
  }
  .main-payment-section .selectbrand-section .input-group select {
    border: none;
    border-left: 1px solid rgba(173, 179, 188, 0.5);
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .selectbrand-section .input-group select:focus {
    box-shadow: none;
  }
  .main-payment-section .selectbrand-section .input-group span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #4a4a4a;
  }
  .main-payment-section .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .shipping-address-section h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .shipping-address-section p {
    padding: 0;
    margin: 0;
  }
  .main-payment-section .shipping-address-section .address {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    padding: 14px 0;
  }
  .main-payment-section .shipping-address-section .address p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 32px;
  }
  .main-payment-section .shipping-address-section .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .shipping-address-section .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .shipping-address-section .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .order-details h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .order-details .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .select-bank-card h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div .form-check {
    margin: 40px 0;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div label {
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .select-bank-card .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .select-bank-card .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .payment-summary h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .main-payment-section .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .place-order h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .place-order .total-price-div {
    margin-top: 34px;
  }
  .main-payment-section .place-order .total-price-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
  }
  .main-payment-section .place-order .total-price-div p span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #979797;
  }
  .main-payment-section .place-order .total-price-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .place-order .total-price-div label {
    cursor: pointer;
  }
  .main-payment-section .place-order .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .place-order .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .place-order .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .place-order .add-new-address button {
    background: #0076be;
    border-radius: 10px;
  }
  .main-payment-section .place-order .add-new-address .btn-width {
    width: 248px;
    height: 55px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .main-payment-section .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 15px 10px;
  }
  .main-payment-section .selectbrand-section .input-group {
    padding: 14px 0;
  }
  .main-payment-section .selectbrand-section .input-group select {
    border: none;
    border-left: 1px solid rgba(173, 179, 188, 0.5);
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }
  .main-payment-section .selectbrand-section .input-group select:focus {
    box-shadow: none;
  }
  .main-payment-section .selectbrand-section .input-group span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  .main-payment-section .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-payment-section .shipping-address-section h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .shipping-address-section p {
    padding: 0;
    margin: 0;
  }
  .main-payment-section .shipping-address-section .address {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    padding: 14px 0;
  }
  .main-payment-section .shipping-address-section .address p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    line-height: 32px;
  }
  .main-payment-section .shipping-address-section .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .shipping-address-section .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .shipping-address-section .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .order-details h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .order-details .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }
  .main-payment-section .order-details .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
  }
  .main-payment-section .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .select-bank-card h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .select-bank-card .strip-checkmark-div .form-check {
    margin: 40px 0;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .strip-checkmark-div label {
    cursor: pointer;
  }
  .main-payment-section .select-bank-card .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .select-bank-card .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .select-bank-card .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 20px;
  }
  .main-payment-section .payment-summary h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  }
  .main-payment-section .payment-summary .inner-order-details .card-details-div .price {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #000000;
  }
  .main-payment-section .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
  }
  .main-payment-section .place-order h6 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    padding: 25px 0;
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  }
  .main-payment-section .place-order .total-price-div {
    margin-top: 34px;
  }
  .main-payment-section .place-order .total-price-div p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }
  .main-payment-section .place-order .total-price-div p span {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #979797;
  }
  .main-payment-section .place-order .total-price-div input {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .main-payment-section .place-order .total-price-div label {
    cursor: pointer;
  }
  .main-payment-section .place-order .add-new-address {
    padding: 28px 0;
    display: flex;
    align-items: center;
  }
  .main-payment-section .place-order .add-new-address p {
    margin-bottom: 0;
  }
  .main-payment-section .place-order .add-new-address svg {
    margin-right: 5px;
  }
  .main-payment-section .place-order .add-new-address button {
    background: #0076be;
    border-radius: 10px;
  }
  .main-payment-section .place-order .add-new-address .btn-width {
    width: 248px;
    height: 55px;
  }
}
.confirmed-order .order-completed-container {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 40px;
  border-radius: 20px;
}
.confirmed-order .order-completed-container h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding-top: 29px;
}
.confirmed-order .order-completed-container .skip-div p {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding-bottom: 20px;
}
.confirmed-order .order-completed-container .skip-div p span {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #0076be;
  float: right;
}
.confirmed-order .order-completed-container .rate {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #4a4a4a;
  padding-top: 18px;
  margin: 0;
}
.confirmed-order .order-completed-container .start-rating-section {
  padding-top: 40px;
  justify-content: center;
  display: flex;
  padding-bottom: 40px;
}
.confirmed-order .order-completed-container .start-rating-section .stars {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}
.confirmed-order .order-completed-container .start-rating-section .stars__star-icon {
  stroke: #efce4a;
  stroke-width: 2px;
  fill: transparent;
  transition: 0.1s all;
}
.confirmed-order .order-completed-container .start-rating-section .stars__star {
  width: 75px;
  height: 75px;
  position: relative;
  cursor: pointer;
  margin: 10px;
}
.confirmed-order .order-completed-container .start-rating-section .stars__checkbox {
  position: absolute;
  top: -9999px;
  opacity: 0;
  width: 0;
  height: 0;
}
.confirmed-order .order-completed-container .start-rating-section .stars__star:hover > .stars__star-icon {
  fill: #efce4a;
}
.confirmed-order .order-completed-container .start-rating-section .stars__star:hover ~ .stars__star > .stars__star-icon {
  fill: #efce4a;
}
.confirmed-order .order-completed-container .start-rating-section .stars__checkbox:checked + .stars__star > .stars__star-icon {
  fill: #efce4a;
}
.confirmed-order .order-completed-container .start-rating-section .stars__checkbox:checked ~ .stars__star > .stars__star-icon {
  fill: #efce4a;
}
.confirmed-order .order-completed-container .text-msg-div {
  padding: 0 60px;
  justify-content: center;
}
.confirmed-order .order-completed-container .text-msg-div textarea {
  background: linear-gradient(0deg, #f4f9fb, #f4f9fb), #f6f6f6;
  border-radius: 10px;
  min-height: 150px;
  padding: 10px;
  resize: none;
}
.confirmed-order .order-completed-container .text-msg-div .submit-btn {
  padding-top: 40px;
}
.confirmed-order .order-completed-container .text-msg-div .submit-btn button {
  padding: 14px 155px;
  margin: 0 auto;
  background: #0076be;
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  transition: 0.2s ease-in;
}
.confirmed-order .order-completed-container .text-msg-div .submit-btn button:hover {
  background-color: white;
  border: 1px solid #0076be;
  color: #0076be;
}

.multi-range-slider {
  display: flex;
  position: relative;
  border: none !important;
  border-radius: 10px;
  padding: 20px 10px;
  box-shadow: none !important;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
.multi-range-slider .bar-inner {
  background-color: #0076be !important;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: space-between;
  position: relative;
  border: none !important;
  justify-content: space-between;
  box-shadow: none !important;
}
.multi-range-slider .thumb::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #0076be !important;
  background: #0076be !important;
  border-radius: 50%;
  box-shadow: none !important;
  z-index: 1;
  margin: -8px -12px;
  cursor: pointer;
}
.multi-range-slider .bar-left {
  padding: 1px 0px !important;
  background-color: #adb3bc !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.multi-range-slider .bar-right {
  padding: 1px 0px !important;
  background-color: #adb3bc !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.multi-range-slider .caption {
  display: flex !important;
}
.multi-range-slider .caption span {
  margin-top: 130px;
}
.multi-range-slider .caption .max-caption {
  background-color: transparent !important;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000 !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.multi-range-slider .caption .max-caption::before {
  content: "$";
}
.multi-range-slider .caption .min-caption {
  background-color: transparent !important;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000 !important;
  border-radius: none !important;
  box-shadow: none !important;
}
.multi-range-slider .caption .min-caption::before {
  content: "$";
}

.main-filter .filter-container {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  padding-right: 24px;
  padding-left: 24px;
  padding-bottom: 40px;
  border-radius: 20px;
  max-width: 586px;
  width: 586px;
}
.main-filter .filter-container .heading-div {
  position: relative;
  margin-bottom: 50px;
}
.main-filter .filter-container .heading-div h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
  padding-top: 29px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
}
.main-filter .filter-container .heading-div .cross-icon {
  position: absolute;
  right: 20px;
  top: 27px;
}
.main-filter .filter-container .main-filter-div {
  padding-left: 70px;
  padding-right: 70px;
}
.main-filter .filter-container .main-filter-div .form-floating {
  margin-bottom: 30px;
}
.main-filter .filter-container .main-filter-div .form-select {
  display: block;
  width: 100%;
  background-color: #fff;
  background-image: url("../images/icons/drop-down-icon.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 10px 12px;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  padding-top: 25px;
  padding-left: 3px;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.main-filter .filter-container .main-filter-div .form-select:focus {
  box-shadow: none;
}
.main-filter .filter-container .main-filter-div .form-floating > label {
  padding: 1rem 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4a4a4a;
}
.main-filter .filter-container .main-filter-div .filter-div {
  padding-top: 11px;
}
.main-filter .filter-container .main-filter-div .filter-div label {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4a4a4a;
}
.main-filter .filter-container .main-filter-div button {
  background: #0076be;
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  border: none;
  padding: 14px 0;
  margin-top: 40px;
  margin-bottom: 50px;
  transition: 0.2s ease-in;
}
.main-filter .filter-container .main-filter-div button:hover {
  border: 1px solid #0076be;
  background-color: #ffffff;
  color: #0076be;
}
.main-filter .filter-container .multi-range-slider {
  padding: 20px 0;
}

@media (max-width: 786px) {
  .main-filter .filter-container {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 40px;
    border-radius: 20px;
    max-width: 100%;
    max-height: 499.83px;
  }
  .main-filter .filter-container .main-filter-div {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.footer-heading {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
}

.style-list {
  list-style: none;
  padding: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
}

.anchor-style {
  text-decoration: none;
  color: #ffffff;
}

.anchor-style:hover {
  color: #ed1c24;
}

.footer {
  background-color: #000000;
}
.footer .footer-1 {
  padding: 100px 0 30px 0;
}
.footer .footer-1 p {
  padding-top: 30px;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  width: 81%;
}
.footer .footer-2 {
  padding: 100px 0 30px 0;
}
.footer .footer-2 .footerlists {
  display: flex;
}
.footer .footer-2 .footerlists .second-list {
  padding-left: 70px;
}
.footer .footer-3 {
  padding: 100px 0 30px 0;
}
.footer .footer-3 img {
  padding: 10px 0;
}
.footer .footer-3 .app-section a {
  display: block;
}
.footer .bottom-border {
  border: 1px solid #adb3bc;
}
.footer .copyright {
  padding-top: 20px;
  text-align: center;
  color: #ffffff;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
}

@media screen and (min-width: 1700px) {
  .footer-heading {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }
  .footer {
    background-color: #000000;
  }
  .footer .footer-1 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-1 p {
    padding-top: 30px;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    width: 81%;
  }
  .footer .footer-2 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-2 .footerlists {
    display: flex;
  }
  .footer .footer-2 .footerlists .second-list {
    padding-left: 70px;
  }
  .footer .footer-3 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-3 img {
    padding: 10px 0;
  }
  .footer .bottom-border {
    border: 1px solid #adb3bc;
  }
  .footer .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
  .footer-heading {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }
  .footer {
    background-color: #000000;
  }
  .footer .footer-1 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-1 p {
    padding-top: 30px;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    width: 81%;
  }
  .footer .footer-2 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-2 .footerlists {
    display: flex;
  }
  .footer .footer-2 .footerlists .second-list {
    padding-left: 70px;
  }
  .footer .footer-3 {
    padding: 100px 0 30px 0;
  }
  .footer .footer-3 img {
    padding: 10px 0;
  }
  .footer .bottom-border {
    border: 1px solid #adb3bc;
  }
  .footer .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .footer-heading {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  .style-list {
    list-style: none;
    padding: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }
  .footer {
    background-color: #000000;
  }
  .footer .footer-1 {
    padding: 30px 0 0 0;
  }
  .footer .footer-1 p {
    padding-top: 30px;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    width: 81%;
  }
  .footer .footer-2 {
    padding: 30px 0 0 0;
  }
  .footer .footer-2 .footerlists {
    display: flex;
  }
  .footer .footer-2 .footerlists .second-list {
    padding-left: 70px;
  }
  .footer .footer-3 {
    padding: 30px 0 0 0;
  }
  .footer .footer-3 img {
    padding: 10px 0;
  }
  .footer .bottom-border {
    border: 1px solid #adb3bc;
  }
  .footer .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .footer-heading {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    padding-left: 0;
  }
  .style-list {
    list-style: none;
    padding: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }
  .footer {
    background-color: #000000;
  }
  .footer .footer-1 {
    padding: 30px 0 0 0;
  }
  .footer .footer-1 p {
    padding-top: 30px;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
  }
  .footer .footer-2 {
    padding: 30px 0 0 0;
  }
  .footer .footer-2 .footerlists {
    display: flex;
    padding: 0;
  }
  .footer .footer-2 .footerlists .second-list {
    padding-left: 70px;
  }
  .footer .footer-3 {
    padding: 30px 0 0 0;
  }
  .footer .footer-3 img {
    padding: 10px 0;
  }
  .footer .footer-3 .app-section {
    padding-top: 50px;
  }
  .footer .bottom-border {
    border: 1px solid #adb3bc;
  }
  .footer .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .footer-heading {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
    padding-left: 0;
  }
  .style-list {
    list-style: none;
    padding: 0;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
  .anchor-style {
    text-decoration: none;
    color: #ffffff;
  }
  .anchor-style:hover {
    color: #ed1c24;
  }
  .footer {
    background-color: #000000;
  }
  .footer .footer-1 {
    padding: 30px 0 0 0;
  }
  .footer .footer-1 p {
    padding-top: 30px;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    width: 100%;
  }
  .footer .footer-2 {
    padding: 30px 0 0 0;
  }
  .footer .footer-2 .footerlists {
    display: flex;
    padding: 0;
  }
  .footer .footer-2 .footerlists .second-list {
    padding-left: 70px;
  }
  .footer .footer-3 {
    padding: 30px 0 0 0;
  }
  .footer .footer-3 img {
    padding: 10px 0;
  }
  .footer .footer-3 .app-section {
    padding-top: 50px;
  }
  .footer .bottom-border {
    border: 1px solid #adb3bc;
  }
  .footer .copyright {
    padding-top: 20px;
    text-align: center;
    color: #ffffff;
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .aboutus-container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 992px) {
  .second-navbar-container {
    display: flex;
    justify-content: space-between;
  }
  .second-navbar-container nav ul {
    display: block;
  }
  .second-navbar-container nav ul li {
    padding: 15px 0;
  }
  .second-navbar-container nav form button {
    display: none;
  }
  .second-navbar-container .filter-button {
    padding-bottom: 0.5rem;
  }
  .second-navbar-container .filter-button .blue-button {
    color: #fff;
    background: #0076BE;
    font-family: "RocknRoll One", sans-serif;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    border-radius: 10px;
    padding: 10px 15px;
    border-color: #0076BE;
    border: 0;
    float: right;
    margin: 18px 30px 0 0;
  }
  .second-navbar-container .filter-button .blue-button:hover {
    color: #FFFFFF;
  }
  .second-navbar-container .filter-button svg {
    margin-right: 10px;
  }
}
.main-search-container .upper-searchbar {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
.main-search-container .upper-searchbar .searchicon-inputdiv {
  display: flex;
  align-items: center;
  width: 100%;
}
.main-search-container .upper-searchbar .searchicon-inputdiv input {
  font-family: "SF Pro Text", sans-serif;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}
.main-search-container .upper-searchbar .searchbtn-closediv {
  display: flex;
  align-items: center;
}
.main-search-container .upper-searchbar .searchbtn-closediv button {
  background: #0076be;
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  min-width: 126px;
  line-height: 45px;
  border: none;
  margin-right: 20px;
}
.main-search-container .upper-searchbar .searchbtn-closediv button:focus {
  box-shadow: none;
}
.main-search-container .bottom-searchbar {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-top: 10px;
  padding-bottom: 36px;
}
.main-search-container .bottom-searchbar .search-tabs {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.main-search-container .bottom-searchbar .search-tabs .nav-pills .nav-item {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.main-search-container .bottom-searchbar .search-tabs .nav-pills .nav-item .nav-link {
  color: #000000;
}
.main-search-container .bottom-searchbar .search-tabs .nav-pills .nav-item .active {
  background: transparent;
  color: #48bf91;
  border-bottom: 5px solid #48bf91;
  border-radius: 0;
  position: relative;
  z-index: 1;
}
.main-search-container .bottom-searchbar .bottom-borderr {
  border-bottom: 1px solid rgba(173, 179, 188, 0.5);
  margin-top: -29px;
}
.main-search-container .bottom-searchbar .search-results-div .tab-content .all-div {
  padding-left: 20px;
  padding-right: 20px;
}
.main-search-container .bottom-searchbar .search-results-div .tab-content .all-div .heading-div {
  margin-top: 21px;
}
.main-search-container .bottom-searchbar .search-results-div .tab-content .all-div .heading-div h6 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.main-search-container .bottom-searchbar .search-results-div .tab-content .all-div .search-results-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 19px;
}
.main-search-container .bottom-searchbar .search-results-div .tab-content .all-div .search-results-div p {
  margin: 0;
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4a4a4a;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-search-container .upper-searchbar .searchbtn-closediv button {
    background: #0076be;
    border-radius: 10px;
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    min-width: 90px;
    /* min-height: 25px; */
    line-height: 33px;
    height: 33px;
    border: none;
    margin-right: 10px;
  }
}
.banner-section .banner-wrapper {
  background-image: url("../images/banner-img.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-color: #a19cde;
  padding: 30px 40px;
  border-radius: 20px;
  height: 500px;
}
.banner-section .banner-wrapper .banner-content h1 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 93px;
  color: #FFFFFF;
}
.banner-section .banner-wrapper .banner-content p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: #FFFFFF;
  margin-top: 29px;
}

@media screen and (max-width: 1199px) {
  .banner-section .banner-wrapper {
    background-position: 800px;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .banner-section .banner-wrapper {
    padding: 30px 40px;
    border-radius: 20px;
    height: 500px;
  }
  .banner-section .banner-wrapper .banner-content h1 {
    font-family: "RocknRoll One", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 50px;
    color: #FFFFFF;
  }
  .banner-section .banner-wrapper .banner-content p {
    font-family: "SF Pro Text", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
  }
}
.advertisementarea {
  padding: 150px 0;
  color: #fff;
  text-align: center;
  border-radius: 25px;
  text-align: center;
  background: url("../images/advbg2.png") no-repeat center center;
  background-size: cover;
}
.advertisementarea h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 93px;
  color: #ffffff;
}
.advertisementarea p {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  max-width: 939px;
  text-align: center;
  margin: 0 auto;
}
.advertisementarea a {
  width: 220px;
  height: 55px;
  color: #0076be;
  font-weight: 500;
  font-size: 18px;
  line-height: 55px;
  background: #ffffff;
  border-radius: 10px;
  display: inline-block;
  font-family: "RocknRoll One", sans-serif;
  margin-top: 50px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .advertisementarea {
    padding: 150px 0;
    text-align: center;
    border-radius: 25px;
    text-align: center;
  }
  .advertisementarea h2 {
    font-weight: 400;
    font-size: 28px;
    line-height: 93px;
  }
  .advertisementarea p {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    max-width: 939px;
    text-align: center;
    margin: 0 auto;
  }
  .advertisementarea a {
    width: 220px;
    height: 55px;
    font-weight: 500;
    font-size: 18px;
    line-height: 52px;
    border-radius: 10px;
    display: inline-block;
    font-family: "RocknRoll One", sans-serif;
    margin-top: 50px;
  }
}
.view-button {
  border-radius: 10px;
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #0076BE;
  border-radius: 10px;
  /* padding: 15px 30px; */
  transition: 0.2s ease-in;
  text-decoration: none;
  cursor: pointer;
  width: 126px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}
.view-button:focus {
  outline: none;
  box-shadow: none;
}
.view-button:hover {
  background: #FFFFFF;
  color: #0076BE;
}

.slider-btn {
  background: #FFFFFF;
  border: 1px solid #ADB3BC;
  border-radius: 10px;
  padding: 14px 18px;
  cursor: pointer;
}
.slider-btn:focus {
  outline: none;
  box-shadow: none;
}
.slider-btn svg {
  transform: translateY(-2px);
}

.product-nav-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-nav-heading h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
}
.product-nav-heading .slider-btns {
  display: flex;
  align-items: center;
}
.product-nav-heading .slider-btns .view-button {
  margin-right: 30px;
}
.product-nav-heading .slider-btns .slider-arrow-btns .slider-btn:not(:first-child) {
  margin-left: 10px;
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .view-button {
    font-size: 16px;
  }
  .product-nav-heading {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .product-nav-heading h2 {
    font-size: 24px;
  }
  .product-nav-heading .slider-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .product-nav-heading .slider-btns .view-button {
    margin-right: 30px;
  }
  .product-nav-heading .slider-btns .slider-arrow-btns .slider-btn:not(:first-child) {
    margin-left: 10px;
  }
}
.product-box {
  background: #FFFFFF;
  border-radius: 11.0891px;
  margin-top: 10px;
  border: 0;
}
.product-box img {
  padding: 5px;
}
.product-box .card-title {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
}
.product-box p {
  font-family: "SF Pro Text", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #000000;
}

.trendingproduct .trending-nav-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.trendingproduct .trending-nav-heading h2 {
  font-family: "RocknRoll One", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #000000;
  margin-bottom: 10px;
}
.trendingproduct .product-box {
  margin-bottom: 20px;
}

/* Column 5  */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}
@media screen and (min-width: 0) and (max-width: 576px) {
  .view-button {
    font-size: 16px;
  }
  .slider-btn {
    padding: 14px 18px;
  }
  .trendingproduct .trending-nav-heading {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .trendingproduct .trending-nav-heading h2 {
    font-size: 24px;
  }
  .trendingproduct .trending-nav-heading .slider-btns {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .trendingproduct .trending-nav-heading .slider-btns .view-button {
    margin-right: 30px;
  }
  .trendingproduct .trending-nav-heading .slider-btns .slider-arrow-btns .slider-btn:not(:first-child) {
    margin-left: 10px;
  }
}
.modal-content {
  background-color: transparent;
  border: none;
}

.margin-bottom-150 {
  margin-bottom: 150px;
}

