.advertisementarea {
  padding: 150px 0;
  // background-repeat: no-repeat;
  color: #fff;
  // background-size: 100% 100%;
  text-align: center;
  border-radius: 25px;
  text-align: center;
  background: url("../images/advbg2.png") no-repeat center center;
  background-size: cover;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 93px;
    color: #ffffff;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    max-width: 939px;
    text-align: center;
    margin: 0 auto;

  }

  a {
    width: 220px;
    height: 55px;
    color: #0076be;
    font-weight: 500;
    font-size: 18px;
    line-height: 55px;
    background: #ffffff;
    border-radius: 10px;
    display: inline-block;
    font-family: $primary-fonts;
    margin-top: 50px;
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .advertisementarea {
        padding: 150px 0;
        text-align: center;
        border-radius: 25px;
        text-align: center;

        h2 {
          font-weight: 400;
          font-size: 28px;
          line-height: 93px;
        }
      
        p {
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          max-width: 939px;
          text-align: center;
          margin: 0 auto;
      
        }
      
        a {
          width: 220px;
          height: 55px;
          font-weight: 500;
          font-size: 18px;
          line-height: 52px;
          border-radius: 10px;
          display: inline-block;
          font-family: $primary-fonts;
          margin-top: 50px;
        }
      }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {

}
