@import "color.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $secondary-fonts;
  background-color: #f5f8ff;
  overflow-x: hidden;
}
body.active {
  overflow: hidden;
  &::before {
    content: "";
    background: rgb(16 16 16 / 50%);
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
.mainpadding {
  padding: 0 50px;
}
.main-margin {
  margin: 32px 60px;
}

h2,
h3 {
  font-family: $primary-fonts;
}

/**************************************8
            Header
*****************************************/
.header {
  .black {
    background: $theme-black;
    padding: 15px 0;
  }

  .profilelist {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0px;
  }

  .profilelist li {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    margin: 0 5px; /* border-radius: 150px; */
  }

  .layericonbg {
    background: #1c1c1c;
    width: 40px;
    height: 40px;
    border-radius: 150px;
    line-height: 36px;
  }

  nav ul {
    display: flex;
  }

  nav ul li {
    padding: 20px 107px 28px 0;
  }
  nav ul li a {
    font-weight: 500;
    font-size: 18px;
    color: #4a4a4a;
    font-family: $secondary-fonts;
  }

  nav ul li a:hover {
    color: #0076be;
    // font-weight: 600;
  }

  nav ul li a.active {
    color: #0076be;
    font-weight: 600;
  }
}

// .homebanner {    height: 600px;
//     background-size: cover;
//     background-position: 100% 100%; border-radius: 25px;

//   .headingcontent {
//     padding: 0 60px;
//     color: #fff;
// }
//    .headingcontent h2 {
//     font-size: 60px;
//     line-height: 68px;
// }
//  .headingcontent p {
//     font-size: 18px;
//     line-height: 24px;
// }

// }

section.newfeature-box {
  margin: 25px auto;
}

.featurebox {
  .card {
    border: 0px;
    background-color: transparent;
  }

  .card-img-overlay {
    top: initial;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    border-radius: 0px 0px 20px 20px;
  }

  .card h5.card-title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #fff;
  }
}

.storeHeading-title h2 {
  font-family: $primary-fonts;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #000;
  margin: 0;
  align-items: center;
}

.slider-btns {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.viewall-button {
  background: #0076be;
  font-family: $primary-fonts;
  border-radius: 10px;
  color: #fff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 43px;
  width: 126px;
  height: 45px;
  text-align: center;
  margin: 0 20px;
}

.storeboxesarea {
  width: 100%;
  display: inline-block;
  background: #fff;
  padding: 6px;
  border-radius: 17px;
  /* margin-left: 25px; */
}

.storeboxesarea + .storeboxesarea {
  margin-left: 20px;
}

.storeboxesarea img {
  width: 100%;
  border-radius: 5px;
}

.storeboxesarea h3 {
  color: #000000;
  font-style: normal;
  font-weight: 400;
  font-family: $secondary-fonts;
  font-size: 16px;
  padding: 15px 6px 6px;
  line-height: 20px;
  margin: 0;
}

.storeboxesarea h5 {
  color: #000000;
  font-family: $secondary-fonts;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  padding: 0px 6px 6px;
}

.swiper-button-prev {
  position: absolute;
  right: 20px;
  top: 0px;
  background: #c11c1c;
  width: 20px;
  height: 20px;
  z-index: 9;
}

.swiper-button-next {
  position: absolute;
  right: 0;
  top: 0px;
  background: #c11c1c;
  width: 20px;
  height: 20px;
  z-index: 9;
}
.main-breadcrumbs-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #adb3bc;
  .breadcrumbs {
    padding: 20px 0;

    ul {
      list-style: none;
    }

    ul li {
      display: inline-block;
      padding-right: 15px;
    }
    ul li + li {
      padding: 0 8px;
      margin: 0 10px;
      position: relative;
    }
    ul li + li::before {
      position: absolute;
      top: 9px;
      left: -12px;
      width: 6px;
      height: 2px;
      background: #adb3bc;
      content: "";
    }
    ul li a {
      color: #adb3bc;
      font-weight: 400;
      font-size: 13px;
      font-family: $primary-fonts;
    }
    ul li.activelinkgray a {
      color: #000000;
    }
  }
  .filter-reset-btn {
    button {
      font-family: $secondary-fonts;
      background-color: transparent;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #0076be;
      border: none;
    }
  }
}

.pagewrapper {
  h3 {
    color: #000;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 8px;
  }

  background-color: #fff;
  border-radius: 20px;
  &.pt-50 {
    padding-top: 50px;
  }

  &.pb-50 {
    padding-bottom: 50px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    font-family: $secondary-fonts;
    line-height: 28px;
    margin: 0;
    padding-bottom: 40px;
    color: #4a4a4a;
  }
}

.whitebox-area {
  background: #fff;
  padding: 25px 20px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 100px;
    height: 100px;
    border-radius: 150px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    margin: 0px;
    color: #000;
  }
}

/**************************************8
            Splash Screen
*****************************************/

section.splashscreen {
  height: 100vh;
  position: relative;
  background: url(../images/splashBg.png) repeat-x;
  background-position: center bottom;
  background-size: cover;
  background-color: #2ce9fe;

  // @keyframes animatedBackground  {
  //     0% {
  //         transform: translate(0);
  //       }
  //       100% {
  //         transform: translate(-100px); /* The image width */
  //       }
  //   }

  .container {
    height: 100vh;
  }

  .middlearea {
    text-align: center;
    color: #fff;
  }
  .splashLogo img {
    width: 250px;
  }

  .splashLogo h2 {
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    margin-top: 30px;
  }
  .splashLogo h2 span {
    font-weight: 400;
    font-size: 36px;
    line-height: 52px;
    display: block;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    padding: 20px 0 0;
  }
  .splashLogo a {
    background-color: #fff;
    padding: 10px;
    display: block;
    max-width: 432px;
    height: 55px;
    margin: auto;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    line-height: 34px;
    color: #0076be;
    font-family: $primary-fonts;
    margin-top: 50px;
  }
}

.LightWaves {
  animation: lightwaves 2s infinite;
  position: relative;
}

@keyframes lightwaves {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
    transform: scale(1.05);
  }
  50% {
    transform: translate(25px, 5px);
  }
  75% {
    transform: translate(12px, 10px);
    transform: scale(1.05);
  }
}

.mt-100 {
  margin-top: 110px;
}

/**************************************8
            Footer
*****************************************/

// .footer {background:$theme-black; padding:90px 0 35px;  margin-top: 150px;;

// .footer-para {padding:30px 150px 30px 0}

// .footer-para p { font-size: 16px; font-weight: 400; line-height: 30px; color:#fff}

// .fboxarea h3 {color:#fff; font-weight: 400;     font-size: 24px; padding-bottom: 28px; }

//  .fboxarea   li a {font-weight: 400;
//     font-size: 18px;
//     line-height: 36px;  color:#fff; }

// }
// ul.list-colum2 {
//     columns: 2; padding-right:100px ;
// }

.spacearealink {
  width: 100%;
  height: 1px;
  background-color: #adb3bc;
  opacity: 0.25;
  margin: 50px 0;
}
.mgr-b30 {
  margin-bottom: 30px;
}

.branddetailbanner {
  background: #fff;
  padding: 36px 0;
  border-radius: 20px;
  text-align: center;

  h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 43px;
    padding-top: 10px;
    margin: 0px;
    color: #000;
  }

  img {
    width: 125px;
    height: 125px;
    border-radius: 150px;
  }
}

.trandingareabox .storeboxesarea {
  width: 330px;
  margin: 0 0 30px 30px;
}

.trandingareabox .storeboxesarea:nth-child(5n + 1) {
  margin-left: 0px;
}

#js-prev1,
#js-next1,
#js-prev2,
#js-next2 {
  background: #ffffff;
  border: 1px solid #adb3bc;
  border-radius: 10px;
  padding: 0 !important;
  cursor: pointer;
  margin: 0 0 0 6px;
  width: 45px;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  text-align: center;
}

.blue-button {
  color: #fff;
  background: #0076be;
  font-family: $primary-fonts;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 15px;
  border-color: #0076be;
  border: 0;
  float: right;
  margin: 18px 0 0 0;
  text-decoration: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    color: #ffffff;
  }
}

.button.submitbtn {
  width: 49%;
  line-height: 37px;
  font-size: 22px;
}

@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1920px;
    margin: auto;
  }
  .mainpadding {
    max-width: 1920px;
    margin: auto;
  }
}

@media only screen and (min-width: 1560px) {
  .trandingareabox .storeboxesarea {
    width: 260px;
  }
}
@media only screen and (max-width: 768px) {
  body header ul.profilelist {
    justify-content: center;
  }
  .logos {
    text-align: center;
    padding-bottom: 15px;
  }
  .header .profilelist {
    justify-content: center;
  }
  .homebanner .headingcontent {
    padding: 0 10px;
  }
  .homebanner .headingcontent h2 {
    font-size: 28px;
    line-height: inherit;
  }

  footer .footer-para {
    padding: 0;
  }

  .homebanner {
    height: 400px;
    background-size: 100% 100%;
  }

  .footer .footer-para {
    padding: 30px 0px;
  }

  .featurebox {
    margin-bottom: 25px;
  }

  .fboxarea {
    margin-bottom: 30px;
  }

  .advertisementarea {
    padding: 50px 0;
    .contentheading {
      padding: 0 30px;
      width: 100%;
    }
    .contentheading h2 {
      font-size: 28px;
      line-height: inherit;
    }

    .contentheading p {
      font-size: 16px;
      line-height: inherit;
    }
    .contentheading a {
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: 1700px) {
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .homebanner .col-md-5.col-sm-12.col-12.my-auto {
    width: 100%;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .mainpadding {
    padding: 0 0;
  }
  .main-margin {
    margin: 32px 10px;
  }

  section.splashscreen {
    height: 100vh;
    position: relative;
    background: url(../images/splashBg.png) repeat-x;
    background-position: center bottom;
    background-size: cover;
    background-color: #2ce9fe;

    // @keyframes animatedBackground  {
    //     0% {
    //         transform: translate(0);
    //       }
    //       100% {
    //         transform: translate(-100px); /* The image width */
    //       }
    //   }

    .container {
      height: 100vh;
    }

    .middlearea {
      text-align: center;
      color: #fff;
    }
    .splashLogo img {
      width: 250px;
    }

    .splashLogo h2 {
      color: #fff;
      margin: 0;
      font-weight: 400;
      font-size: 20px;
      line-height: 35px;
      margin-top: 30px;
    }
    .splashLogo h2 span {
      font-weight: 400;
      font-size: 25px;
      line-height: 52px;
      display: block;
    }

    p {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      margin: 0;
      padding: 20px 0 0;
    }
    .splashLogo a {
      background-color: #fff;
      padding: 10px;
      display: block;
      max-width: 432px;
      height: 55px;
      margin: auto;
      border-radius: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;

      line-height: 34px;
      color: #0076be;
      font-family: $primary-fonts;
      margin-top: 50px;
    }
  }
  .pagewrapper {
    h3 {
      font-size: 30px;
      line-height: 58px;
      margin-bottom: 8px;
    }
    &.pt-50 {
      padding-top: 50px;
    }

    &.pb-50 {
      padding-bottom: 50px;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      margin: 0;
      padding-bottom: 40px;
      color: #4a4a4a;
    }
  }
  .main-breadcrumbs-div {
    display: block;
    align-items: center;
    justify-content: space-between;
    .breadcrumbs {
      padding: 20px 0;

      ul {
        list-style: none;
      }

      ul li {
        display: inline-block;
        padding-right: 15px;
      }
      ul li + li {
        padding: 0 8px;
        margin: 0 10px;
        position: relative;
      }
      ul li + li::before {
        position: absolute;
        top: 9px;
        left: -12px;
        width: 6px;
        height: 2px;
        content: "";
      }
      ul li a {
  
        font-weight: 400;
        font-size: 13px;
      }
      ul li.activelinkgray a {
        color: #000000;
      }
    }
    .filter-reset-btn {
      button {
        background-color: transparent;
        font-size: 16px;
        border: none;
        margin-left: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .branddetailbanner {
    padding: 36px 0;
    border-radius: 20px;
    text-align: center;
  
    h2 {
      font-size: 25px;
      line-height: 43px;
      padding-top: 10px;
      margin: 0px;
    }
  
    img {
      width: 125px;
      height: 125px;
      border-radius: 150px;
    }
  }
}
