.main-review-section {
  
  .review-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      padding: 12px;
    }

    .review-cards {
      padding: 10px 0;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      .review-img-section {
        h5 {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: #000000;
          margin: 0;
        }
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: #000000;
          margin: 0;
        }
        small {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1199px) {
  .main-review-section .review-section .review-cards .review-img-section h5 {
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin: 0;
    padding-top: 10px;
  }

  .main-review-section .review-section .review-cards .review-img-section p {
  font-family: $secondary-fonts;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    margin: 0;
    padding-top: 5px;
  }
  .card-body.py-0.px-3 {
    padding-left: 0 !important;
  }
}



@media (min-width: 768px) and (max-width: 1024px) {
  .order-review-card-width {
    width: 90%;
  }
}
