@font-face {
  font-family: 'RocknRoll One';
  src: url('../fonts/RocknRollOne-Regular.woff2') format('woff2'),
      url('../fonts/RocknRollOne-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Heavy.woff2') format('woff2'),
//       url('../fonts/SFProText-Heavy.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Display';
//   src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
//       url('../fonts/SFProDisplay-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Bold.woff2') format('woff2'),
//       url('../fonts/SFProText-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Light.woff2') format('woff2'),
//       url('../fonts/SFProText-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Medium.woff2') format('woff2'),
//       url('../fonts/SFProText-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Regular.woff2') format('woff2'),
//       url('../fonts/SFProText-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'SF Pro Text';
//   src: url('../fonts/SFProText-Semibold.woff2') format('woff2'),
//       url('../fonts/SFProText-Semibold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Medium.woff2') format('woff2'),
      url('../fonts/SFProText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/SFProText-Regular.woff2') format('woff2'),
      url('../fonts/SFProText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



/*# sourceMappingURL=color.css.map */
/*
********************************************
* Global Color Theme
********************************************
*/

$theme-blue: #0076be;
$theme-black: #000000;
$theme-grey: #4a4a4a;
$theme-feild-grey: #adb3bc;
$theme-background: #F5F8FF;
$theme-green: #48bf91;
$theme-white: #ffffff;
$theme-border: #e8e8e8;
$primary-fonts: 'RocknRoll One', sans-serif;
$secondary-fonts: 'SF Pro Text', sans-serif;
/*
********************************************
* Scrollbar Style
********************************************
*/

::-webkit-scrollbar {
  width: 5px;
  // display: none;
}

::-webkit-scrollbar-track {
  background: $theme-white;
}

::-webkit-scrollbar-thumb {
  background: $theme-grey;
}

::-webkit-scrollbar-thumb:hover {
  background: $theme-black;
}

/*
********************************************
* Number Input Style
********************************************
*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
********************************************
* Global Style
********************************************
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
font-family: $secondary-fonts;
}
dl,
ol,
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.text-red {
  color: red;
  font-size: 12px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn {
  box-shadow: none !important;

  &:hover,
  &:focus {
    box-shadow: none !important;
  }
}

input,
.form-control {
  font-size: 12px;
  height: 55px;
  border-radius: 10px;
  padding: 0 1rem;
  border: none;
  box-shadow: none;
  outline: none;

  &::placeholder {
    color: $theme-grey;
    font-weight: 300;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.input-group {
  border-radius: 10px;
  border: 1px solid $theme-feild-grey;
  transition: all 0.5s ease;

  &:hover,
  &:focus {
    border: 1px solid $theme-feild-grey;
  }

  .input-group-text {
    background-color: $theme-white;
    border: none;
    border-radius: 10px;
  }
}

/*
********************************************
* Theme Font Size, Family And Weight 
********************************************
*/

h2,
.h2 {
  font-size: 36px;
  font-weight: 600;
}

h4,
.h4 {
  font-size: 24px;
  font-weight: 500;
}

h5,
.h5 {
  font-size: 20px;
  font-weight: 500;
}

h6,
.h6 {
  font-size: 18px;
  font-weight: 400;
}

p,
span,
.form-label {
  font-size: 14px;
  font-weight: 400;
}

a,
.nav-link {
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

small {
  font-size: 12px;
  font-weight: 400;
}

ul {
  li {
    font-size: 12px;
  }
}

.button,
a.button,
button.button {
  &.btn-solid-blue {
  font-family: $primary-fonts;
    max-width: 430px;
    border-radius: 10px;
    border: 2px solid transparent;
    background-color: #000;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease;
    background-color: #0076be;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    height: 55px;
    line-height: 50px;


    &:hover,
    &:focus,
    &:focus-within {
      color: $theme-black;
      background: transparent;
      border: 2px solid $theme-blue;
      color: $theme-blue;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-outline-blue {
    border: 2px solid $theme-blue;
    padding: 15px 0;
    border-radius: 10px;

    box-shadow: none;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $theme-blue;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-solid-green {
  font-family: $primary-fonts;
    border-radius: 10px;
    max-width: 430px;
    border: 2px solid transparent;
    background-color: #000;
    outline: none;
    box-shadow: none;
    transition: all 0.5s ease;
    background-color: $theme-green;
    color: #fff;
    font-weight: 400;
    font-size: 18px;
    height: 55px;
    line-height: 50px;

    &:hover,
    &:focus,
    &:focus-within {
      color: $theme-black;
      background: transparent;
      border: 2px solid $theme-green;
      color: $theme-green;
      box-shadow: none;
      outline: none;
    }
  }

  &.btn-outline-green {
    border: 2px solid $theme-green;
    padding: 15px 0;
    border-radius: 10px;

    box-shadow: none;
    transition: all 0.5s ease;

    &:hover,
    &:focus,
    &:focus-within {
      background-color: $theme-green;
      box-shadow: none;
      outline: none;
    }
  }
}


button.button {
    &.btn-solid-blue {
        line-height: unset;
    }
}


.home-filter-button {
  text-decoration: none;
  color: #fff;
  background: #0076be;
  font-family: $primary-fonts;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 10px;
  /* padding: 10px 15px; */
  border-color: #0076be;
  border: 0;
  float: right;
  // margin: 18px 0 0 0;
  text-decoration: none;
  width: 126px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  svg{
    margin-right: 10px;
  }
  &:hover{
    color:#fff;
  }
}

.margin-top-40{
  margin-top: 40px;
}

.margin-top-32{
  margin-top: 32px;
}

.margin-right-30{
  margin-right: 30px !important;
}