
@import 'color.scss'; 
@import '_auth.scss'; 
@import 'contactus.scss'; 
@import 'home.scss'; 
@import 'productdetailpage.scss'; 
@import 'sidemenu.scss'; 
@import 'editprofilepage.scss'; 
@import 'brandcardpage.scss'; 
@import 'walletpage.scss'; 
@import 'orderreviewpage.scss'; 
@import 'settingsidemenu.scss'; 
@import 'changepasswordpage.scss'; 
@import 'termspage.scss'; 
@import 'privacypage.scss'; 
@import 'advertisementpage.scss'; 
@import 'logoutalert.scss'; 
@import 'notificationpopup.scss'; 
@import 'profilepopup.scss';
@import 'deletepopup.scss';
@import 'myorders.scss';
@import 'storechat.scss';
@import 'shoppingcart.scss';
@import 'orderplacedpopup.scss';
@import 'paymentmethod.scss';
@import 'ordercompletedpopup.scss';
@import 'filterpopup.scss';
@import 'footer.scss';
@import 'aboutus.scss';
@import 'brands.scss';
@import 'branddetail.scss';
@import 'header.scss';
@import 'searchpopup.scss';
@import 'banner.scss';
@import 'advertisementbanner.scss';
@import 'sliderproduct.scss';
@import 'productcardstyle.scss';
@import 'trendingproducts.scss';

.modal-content{
    background-color: transparent;
    border: none;
}

.margin-bottom-150{
    margin-bottom: 150px;
}

// .form-floating>label{
//     padding: 1rem 0;

// }

// .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
//     left: 0px !important;
// }

// .form-floating>.form-control {
//     padding: 1rem 0;
// }