.confirmed-order {
  .confirmed-order-container {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 54px;
    max-width: 586px;
    margin: 0 auto;

    .heading-div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(173, 179, 188, 0.5);
      h1 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #000000;
        padding: 25px 0;
        margin: 0 auto;
      }
      svg{
        cursor: pointer;
      }
    }

    .order-svg {
      margin: 20px 0;
      svg {
        display: block;
        margin: 0 auto;
        text-align: center;
      }
    }

    .middle-text {
      p {
        margin: 0;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #000000;
      }
    }
    .small-para {
      margin: 0;
    font-family: $secondary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      text-align: center;
      color: #4a4a4a;
      margin-bottom: 25px;

    }
    a {
      background: #0076be;
      border-radius: 10px;
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      text-align: center;
      color: #ffffff;
      /* margin-top: 25px; */
      transition: 0.2s ease-in;
      /* padding: 14px 0; */
      display: block;
      max-width: 432px;
      height: 55px;
      line-height: 55px;
      margin: 0 auto;
      &:hover {
        background: #ffffff;
        color: #0076be;
        border: 1px solid #0076be;
      }
    }
  }
}
