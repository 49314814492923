.product-box{
    background: #FFFFFF;
    border-radius: 11.0891px;
    margin-top: 10px;
    border: 0;
    
        img{
            padding: 5px;
        }
        .card-title{
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
        }
        p{
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 16px;
            color: #000000;

        }
}