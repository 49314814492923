.side-menu {
  padding: 10px 20px 30px 20px;
  background: #ffffff;
  border-radius: 20px;
  height: 750px;
  position: relative;

  .inputfield {
    border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0;
    a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      padding-right: 2px;
      display: flex;
      align-items: center;
      span.number {
        background: #48bf91;
        border-radius: 50%;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #ffffff;
        // padding: 2px 5px;
        margin-left: 5px;
        width: 18px;
        height: 18px;
        text-align: center;
        
      }
      &.active {
        color: #0076be;
        &::after {
          display: flex;
          content: " ";
          background-image: url("/src/assets/images/icons/arrow-icon.svg");
          background-repeat: no-repeat;
          height: 28px;
          width: 28px;
          position: absolute;
          right: -10px;
          bottom: 8px;
          align-items: center;
          margin: 0;
          padding: 0;
        }
        path {
          fill: #0076be;
        }
      }
      &.noactive {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        padding-right: 2px;
        &::after {
          display: none;
        }
        path {
          fill: #adb3bc;
        }
      }
    }
    span{
      width: 40px;
      svg {
        // margin-right: 10px;
      }
    }

  }

}

.side-menu-offcanvas {
  padding: 0;
  background: #ffffff;
  border-radius: 20px;
  height: 100vh;
  position: relative;
  .inputfield {
    border-bottom: 1px solid rgb(173, 179, 188, 0.5);
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0;
    a {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      margin: 0;
      padding-right: 2px;

      span {
        background: #48bf91;
        border-radius: 50%;
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #ffffff;
        padding: 2px 5px;
        // margin-left: 10px;
      }
      &.active {
        color: #0076be;
        &::after {
          display: flex;
          content: " ";
          background-image: url("/src/assets/images/icons/arrow-icon.svg");
          background-repeat: no-repeat;
          height: 28px;
          width: 28px;
          position: absolute;
          right: -10px;
          bottom: 8px;
          align-items: center;
          margin: 0;
          padding: 0;
        }
      }
      &.noactive {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        padding-right: 2px;

        &::after {
          display: none;
        }
      }
    }
    svg {
      margin-right: 10px;
    }
  }
}

// MEDIA QUERY

@media (min-width: 1025px) and (max-width: 5000px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: none;
  }
}
@media only screen and (max-width: 1600px) {
  .side-menu {
    .inputfield {
      a {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        margin: 0;
        padding-right: 2px;
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .sidebar-class-col {
    width: 20%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: block !important;
  }
  section.side-menu {
    display: none;
  }
  .sidebar-class-col {
    width: 10%;
  }
}

@media screen and (max-width: 767px) {
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    display: block !important;
  }
  section.side-menu {
    display: none;
  }
  button.me-2.sidebar-btn-canvas.btn.btn-primary {
    margin-bottom: 20px;
  }
}
