.switch {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 24px;
    border-radius: 20px;
    background: #dfd9ea;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
    margin-left: auto;
}
.switch::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 2px;
    width: 18px;
    height: 18px;
    background: #fafafa;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: #34C759;;
}
input:checked + .switch::before {
    left: 20px;
    background: #fff;
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}


.settings{
    background: #FFFFFF;
    border-radius: 20px;
    padding: 24px;
    height: 100%;
    
    h6{
      font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 12px;
        border-bottom: 1px solid rgb(173,179,188, 0.5);
    }
    .setting-tab{

        .inputfield{
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            display: flex;
            align-items: center;
            position: relative;
            padding: 20px 0;
            a{
    
                  font-family: $primary-fonts;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #000000;
                    // padding-left: 40px;
                    display: flex;
                    align-items: center;
                    // span{
                    //     background: #48BF91;
                    //     border-radius: 50%;
                    //   font-family: $primary-fonts;
                    //     font-style: normal;
                    //     font-weight: 400;
                    //     font-size: 10px;
                    //     line-height: 14px;
                    //     color: #FFFFFF;
                    //     padding: 2px 6px;
                    //     margin-left: 10px;
                    // }
                    &.active{
                        color: #0076BE;    
                        path{
                            fill: #0076BE;
                        }    
                    }
                    &.noactive{
                      font-family: $primary-fonts;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                        margin: 0;
                        padding-right: 2px;
    
                        &::after{
                            display: none;
                        }
                        path{
                            fill: #adb3bc;
                        }
                    }
                    span{
                        width: 40px;
                        svg{
                            // margin-right: 10px;
                        }
                    }

            }
        }

    }
}