
.main-brand-card{
    
    .brand-card-section{
        background: #FFFFFF;
        border-radius: 25px;
        height: 100%;
        padding: 20px 24px 0 24px;
        h6{
            font-family: $primary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #000000;
            margin-bottom: 0;
            padding-top: 10px;
        }
    
        .border-bottomm{
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            margin: 15px 0;
        }

        .brand-cards{
            padding: 10px 0;
            border-bottom: 1px solid rgb(173,179,188, 0.5);
            h2{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #000000;
            }
            ul{
                padding: 0;
                li{
                    list-style: none;
                    color: #4A4A4A;
                    span{
                      font-family: $secondary-fonts;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        color: #000000;
                        padding: 2px 0;
                        display: inline-block;
                        padding-right: 42px;
                        width: 100px;
                    }


                }
            }
            a{
              font-family: $primary-fonts;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #ADB3BC;
                border: 1px solid #ADB3BC;
                border-radius: 5px;
                cursor: pointer;
                padding: 8px 15px;
            }
        }

        .add-new-btn{
            padding: 20px 0;
            a{
              font-family: $secondary-fonts;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                color: #0076BE;
                cursor: pointer;
                span{
                  font-family: $secondary-fonts;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #0076BE;
                    cursor: pointer;
                    padding-left: 5px;
                    padding-bottom: 5;
                }
            }
        }
    }
}


@media (min-width:768px) and (max-width:991px)
{
    .brand-section-col.col-xxl-4.col-xl-5.col-md-6.col-sm-12 {
        width: 70%;
    }
}


@media screen and (max-width:575px)
{

.my-auto.brand-logo-div.col-sm-3.col-12 {
    text-align: center;
}
.main-brand-card .brand-card-section .brand-cards ul li span {
    display: block;
}
.my-auto.brand-redeem.col-sm-3.col-12 {
    text-align: center;
}
.brand-card-section > .brand-cards > .row > .brand-details-div > ul {
    display: flex;
    justify-content: space-around;
}
.main-brand-card .brand-card-section .brand-cards h2{
    text-align: center;
}
.brand-details-div.col-sm-6.col-12 {
    margin: 20px 0;
}
.my-auto.brand-redeem.col-sm-3.col-12 {
    padding-bottom: 20px;
}
}