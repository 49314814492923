.main-payment-section {
  
  .selectbrand-section {
    background: #ffffff;
    border-radius: 20px;
    padding: 26px 30px;
    .input-group {
      padding: 14px 0;

      select {
        border: none;
        border-left: 1px solid rgba(173, 179, 188, 0.5);
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        text-overflow: ellipsis !important;
        cursor: pointer;
        &:focus {
          box-shadow: none;
        }
      }
      span {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #4a4a4a;
      }
    }
  }

  .shipping-address-section {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 30px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    p {
      padding: 0;
      margin: 0;
    }
    .address {
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      padding: 14px 0;

      p {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        line-height: 32px;
      }
    }
    .add-new-address {
      padding: 28px 0;
      display: flex;
      align-items: center;
      p{
        margin-bottom: 0;
        margin-top: 1px;

        font-family:  $secondary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #0076BE;
      }
      svg {
        margin-right: 5px;
      }
    }
  }

  .order-details {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }

    .inner-order-details {
      .card-details-div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
        .price {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
  .select-bank-card {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    .strip-checkmark-div {
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      .form-check {
        margin: 40px 0;
        width: 115px;

      }
      input {
        width: 25px;
        height: 30px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
        &:focus{
  
          box-shadow: none;
        }
      }
      label {
        cursor: pointer;
        margin-top: 3px;

      }
    }
    .add-new-address {
      padding: 28px 0;
      display: flex;
      align-items: center;
      p{
        margin-bottom: 0;
        margin-top: 1px;

        font-family:  $secondary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #0076BE;
      }
      svg {
        margin-right: 5px;
      }
    }
  }
  .payment-summary {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }

    .inner-order-details {
      .card-details-div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
        }
        .price {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
  .place-order {
    background: #ffffff;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 30px;
    h6 {
      font-family: $primary-fonts;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      padding: 25px 0;
      border-bottom: 1px solid rgba(173, 179, 188, 0.5);
    }
    .total-price-div {
      margin-top: 34px;

      p {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #000000;

        span {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
        }
      }
      input {
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
    .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
      svg {
        margin-right: 5px;
      }
      button {
        background: #0076be;
        border-radius: 10px;
      }
      .btn-width{
        width: 248px;
        height: 55px;
      }
    }
  }
}

@media screen and (min-width: 1700px) and (max-width: 1920px) {

}

@media screen and (min-width: 992px) and (max-width: 1400px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;
  
        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
  
          &:focus {
            box-shadow: none;
          }
        }
        span {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }
  
    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  
    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;
  
          span {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
          padding: 28px 0;
          display: flex;
          align-items: center;
          p{
            margin-bottom: 0;
          }
        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width{
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;
  
        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
  
          &:focus {
            box-shadow: none;
          }
        }
        span {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }
  
    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  
    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;
  
          span {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
          padding: 28px 0;
          display: flex;
          align-items: center;
          p{
            margin-bottom: 0;
          }
        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width{
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .main-payment-section {
    .selectbrand-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 26px 30px;
      .input-group {
        padding: 14px 0;
  
        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
  
          &:focus {
            box-shadow: none;
          }
        }
        span {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
        }
      }
    }
  
    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  
    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          color: #000000;
  
          span {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
          padding: 28px 0;
          display: flex;
          align-items: center;
          p{
            margin-bottom: 0;
          }
        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width{
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .main-payment-section {
    .selectbrand-section {

      background: #ffffff;
      border-radius: 20px;
      padding: 15px 10px;
      .input-group {
        padding: 14px 0;
  
        select {
          border: none;
          border-left: 1px solid rgba(173, 179, 188, 0.5);
          font-family: $primary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
  
          &:focus {
            box-shadow: none;
          }
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
  
    .shipping-address-section {
      background: #ffffff;
      border-radius: 20px;
      margin-top: 30px;
      padding-left: 24px;
      padding-right: 24px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      p {
        padding: 0;
        margin: 0;
      }
      .address {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        padding: 14px 0;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          line-height: 32px;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  
    .order-details {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
        }
      }
    }
    .select-bank-card {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .strip-checkmark-div {
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
        .form-check {
          margin: 40px 0;
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
        padding: 28px 0;
        display: flex;
        align-items: center;
        p{
          margin-bottom: 0;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
    .payment-summary {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 20px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
  
      .inner-order-details {
        .card-details-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          p {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #000000;
          }
          .price {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #000000;
          }
        }
      }
    }
    .place-order {
      background: #ffffff;
      border-radius: 20px;
      padding-left: 24px;
      padding-right: 24px;
      margin-top: 30px;
      h6 {
        font-family: $primary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #000000;
        padding: 25px 0;
        border-bottom: 1px solid rgba(173, 179, 188, 0.5);
      }
      .total-price-div {
        margin-top: 34px;
  
        p {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #000000;
  
          span {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #979797;
          }
        }
        input {
          width: 25px;
          height: 25px;
          border-radius: 5px;
          margin-right: 10px;
          cursor: pointer;
        }
        label {
          cursor: pointer;
        }
      }
      .add-new-address {
          padding: 28px 0;
          display: flex;
          align-items: center;
          p{
            margin-bottom: 0;
            
          }
  
        svg {
          margin-right: 5px;
        }
        button {
          background: #0076be;
          border-radius: 10px;
        }
        .btn-width{
          width: 248px;
          height: 55px;
        }
      }
    }
  }
}
 
