

  .main-productdetails-section {
    
    .swiper {
      width: 100%;
      height: 100%;
    }
    
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
    
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;


    }
    
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
   
    }
    
    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
    
    .mySwiper2 {
      height: 80%;
      width: 100%;
    }
    
    .mySwiper {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
    
    }
    
    .mySwiper .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 1;
      cursor: pointer;
 

      &:not(:first-child){
        margin-left: 10px;
      }
      // img{
      //   width: 150px;
      //   height: 100px;
      // }
    }

    .mySwiper .swiper-slide-thumb-active {
      opacity: 1;
     
      &:before{
        background: rgb(0 0 0 / 68%);
        position: absolute;
        z-index: 99;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: 10px;
    
      }
    }
    
    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
    .mySwiper .swiper-slide-active:before {

  }
    
    .prodcut-detailcard-slider {
      background-color: #ffffff;
      border-radius: 20px;
      padding: 20px;
      .mySwiper {
        margin-top: 20px;
      }
    }
  
    .product-details-section {
      border-radius: 20px;
      background-color: #ffffff;
      padding: 30px;
      // position: relative;
      height: 100%;
      .top-detail-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .product-detail-category {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #979797;
          margin: 0;
        }
        .product-detail-rating {
          margin: 0;
          span {
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 400;
            font-size: 16.289px;
            color: #979797;
            padding-left: 3px;
          }
        }
      }
  
      h2 {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        margin-top: 10px;
      }
  
      .second-detail-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        h2 {
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          color: #000000;
          margin: 0;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #48bf91;
          margin: 0;
        }
      }
  
      .bottom-border-line {
        border-bottom: 1px solid rgb(173, 179, 188, 0.5);
        margin-top: 38px;
      }
  
      .product-detail-para {
      font-family: $secondary-fonts;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #4a4a4a;
        margin: 27px 0 0 0;
      }
  
      .product-select-size {
        margin-top: 30px;
        position: relative;
        .input-group {
          border: none;
          position: relative;
          .input-group-text {
          font-family: $secondary-fonts;
            background-color: #f5f8ff;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #4a4a4a;
            padding: 24px 30px 24px 11px;
  
            &::after {
              content: "";
              border: 1px solid #adb3bc;
              opacity: 0.6;
              position: absolute;
              width: 2px;
              height: 35px;
              left: 118px;
            }
          }
        }
        .form-select{
          background-image: url("../images/productdetails/droparrow.svg");
          cursor: pointer;
        }
        select {
          font-family: $primary-fonts;
          border: none;
          background-color: #f5f8ff;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #000000;
  
          &:focus {
            box-shadow: none;
          }
          // &:after{
          //   background-image: url("../images/productdetails/droparrow.png");
          //   content: "";
          //   width: 14px;
          //   height: 8px;
          //   position: absolute;
          //   top: 0;
          //   z-index: 99;
          // }
        }
      }
  
      .product-select-color {
        margin-top: 30px;
        display: flex;
        align-items: center;
        width: 100%;
        background: #f5f8ff;
        padding: 24px 11px;
        border-radius: 10px;
        position: relative;
        p {
          margin: 0;
        font-family: $secondary-fonts;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #4a4a4a;
          &::after {
            content: "";
            border: 1px solid #adb3bc;
            opacity: 0.6;
            position: absolute;
            width: 2px;
            height: 35px;
            left: 118px;
            right: 0;
            top: 20px;
        
          }
        }
        .radio-colors {
          display: flex;
          align-items: center;
          margin-left: 26px;
          input {
            width: 20px;
            height: 20px;
            padding: 0;
            margin-left: 10px;
            border: none;
            padding: 5px;
            cursor: pointer;
            &:focus{
              border: none;
              box-shadow: none;
            }
            &:checked{
              outline: 1px solid #0076BE;
              outline-offset: 2px;
            }
          }
          #darkgrey{
            background: #6D6F71;
          }
          #black{
            background: #000000;
          }
          #lightgrey{
            background: #E5E3E1;
          }
          #pink{
            background: #ECBBA4;
          }
        }
      }
  
      .quantity-addcard {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .qunatity-div{
          display: flex;
          align-items: center;
          background: #f5f8ff;
          border-radius: 10px;
          max-width: 114.28px;
          margin-right: 30px;
          position: relative;
          input {
            width: 114.28px;
            background: transparent;
            text-align: center;
          font-family: $secondary-fonts;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #000000;
            &::placeholder {
              text-align: center;
            font-family: $secondary-fonts;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #000000;
            }

          }
          .arrow-down-icon{
            left: 17px;
            position: absolute;
            cursor: pointer;
          }
          .arrow-up-icon{

            position: absolute;
            right: 17px;
            cursor: pointer;
        

          }
          svg{
   
          }
        }

        button {
          background: #0076be;
          border-radius: 10px;
          // padding: 16px 0;
          height: 55px;
          line-height: 55px;
          padding: 0;
          span {
            padding-left: 9px;
          }
        }
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 1199px){
    .product-detail-col-right{
    width:50%;
    }
    .product-details-section {
      margin-top: 20px;
  }
    }

    @media screen and (max-width:767px){
      .product-details-section {
        margin-top: 20px;
    }
    }